export const IntegrationPlot = {
  PTL: 1, // 1, 5
  TABLEAU: 2, // 1, 3
} as const;

export const IntegrationTypePlot = {
  DASHBOARD: 1,
  MENU: 3,
  DASHBOARD_INDICATORS: 5,
} as const;

const AllowedIntegrationTypes = {
  PTL: [IntegrationTypePlot.DASHBOARD, IntegrationTypePlot.DASHBOARD_INDICATORS],
  TABLEAU: [IntegrationTypePlot.DASHBOARD, IntegrationTypePlot.MENU],
} as const;

type ValueOf<T> = Utils.ValueOf<T>;
export type TIntegrationId = ValueOf<typeof IntegrationPlot>;
export type TIntegrationTypeId = ValueOf<typeof IntegrationTypePlot>;
export type TIntegrationKey = keyof typeof IntegrationPlot;
export type TIntegrationTypeKey = keyof typeof IntegrationTypePlot;
// export type TIntegrationAllowed<T extends TIntegrationId> = (typeof AllowedIntegrationTypes)[T][number];
export type TIntegrationTypeIdAllowed<T extends TIntegrationKey> = (typeof AllowedIntegrationTypes)[T][number];
export type TIntegrationTypeAllowed<T extends TIntegrationKey> = keyof {
  [K in keyof typeof IntegrationTypePlot as (typeof IntegrationTypePlot)[K] extends TIntegrationTypeIdAllowed<T>
    ? K
    : never]: K;
};
