import merge from "lodash/merge";
// ? TYPES:
import { ChartOptions, Scale } from "chart.js";
type ChartXAxe = Scale;
export type ChartOptionsExtended = ChartOptions & { plugins: { onComplete?: { afterRender: () => void } } };

// export const DEFAULT_AGG = "max";
export const DISABLE_ANIMATION_AFTER_X_RECORDS = 1800;

export const CHART_COLOURS = [
  "#4393CD",
  "#6AB557",
  "#F2E853",
  "#EB964E",
  "#DB3B37",
  "#7E338B",
  "#6071B0",
  "#4A9F8E",
  "#C2D353",
  "#F4BF64",
  "#E3764A",
  "#942E65",
  "#37294f",
  "#201923",
  "#fcff5d",
  "#7dfc00",
  "#228c68",
  "#3998f5",
  "#8ad8e8",
  "#37294f",
  "#235b54",
  "#c56133",
  "#29bdab",
  "#991919",
  "#f47a22",
  "#277da7",
  "#ffcba5",
  "#3750db",
  "#f22020",
  "#ffc413",
  "#96341c",
  "#d30b94",
  "#0ec434",
  "#2f2aa0",
  "#f07cab",
  "#5d4c86",
];

export const TOOLTIP_STYLES = {
  backgroundColor: "white",
  titleFontColor: "#8C93B3",
  titleMarginBottom: 12,
  titleFontStyle: "normal",
  titleFontSize: 12,
  bodyFontColor: "black",
  bodySpacing: 8,
  bodyFontSize: 14,
  caretSize: 12,
  cornerRadius: 3,
  borderColor: "#2A91E9",
  borderWidth: 1,
  xPadding: 12,
  yPadding: 12,
};

// interface XAxesProps {
//   shouldDisplay?: boolean;
//   minUnit?: TimeUnit | undefined;
//   distribution?: "series" | "linear";
//   timeMin?: any;
//   timeMax?: any;
// }

export const X_AXES = {
  time: (override: Partial<ChartXAxe> = {}): Partial<ChartXAxe> => {
    return merge(
      {},
      {
        id: "x",
        offset: true, // important
        type: "time",
        distribution: "series",
        ticks: {
          source: "auto", // date
          fontColor: "#666",
          autoSkip: false,
          // autoSkip: true,
          // beginAtZero: true,
        },
        // bounds: "ticks",
        // displayFormats: {
        //   hour: "hA",
        // },
        // stacked: false,
        time: {
          isoWeekday: true,
          // displayFormats: {
          //   hour: "h:mm a",
          //   minute: "h:mm a",
          // },
        },
      },
      override
    );
  },
};

const ZOOM_OPTIONS = {
  zoom: {
    mode: "x",
    scaleMode: "x",
    drag: {
      enabled: true,
      borderColor: "rgba(225,225,225)",
      borderWidth: 2,
      backgroundColor: "rgb(225,225,225,0.6)",
    },
  },
  pan: {
    enabled: false,
    mode: "x",
  },
};

const isValueMissing = (v: any) => v === null || v === undefined || isNaN(v);

export const DEFAULT_CHART_OPTIONS: ChartOptionsExtended = {
  responsive: true,
  maintainAspectRatio: false,
  resizeDelay: 0,
  interaction: {
    mode: "index",
    axis: "x",
    intersect: false,
  },
  plugins: {
    zoom: ZOOM_OPTIONS as any,
    tooltip: {
      position: "average",
      callbacks: {},
      filter: (item: any, _data) => !isValueMissing(item.raw.y), // remove NaN / null values from tooltips
      animation: {
        duration: 200,
      },
      // ...TOOLTIP_STYLES,
    },
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
  },
  scales: {
    x: {},
    y: {
      beginAtZero: true,
    },
  },
  transitions: {
    // active: { animation: { duration: 0 } },
    // hide: { animation: { duration: 0 } },
    // reset: { animation: { duration: 0 } },
    resize: { animation: { duration: 0 } },
    // show: { animation: { duration: 0 } },
    zoom: {
      animation: {
        duration: 300,
        easing: "easeOutCubic",
      },
    },
  },
  animation: {
    duration: 600,
    easing: "easeOutCubic",
  },
  elements: {
    line: {
      tension: 0,
    },
  },
};
