export const MODAL_SIZES = {
  WIDTH: {
    dimension: 1030,
    suffix: "px",
  },
  LEFT_SIDE_WIDTH: {
    dimension: 14,
    suffix: "vw",
  },
  LEFT_SIDE_MARGIN: {
    dimension: 18,
    suffix: "px",
  },
};

export const MODAL_INDICATOR_SETUP_STYLES = {
  overflow: "visible",
  maxWidth: MODAL_SIZES.WIDTH.dimension + MODAL_SIZES.WIDTH.suffix,
};

export const MODAL_INDICATOR_SETUP_STYLES_UPDATE = {
  ...MODAL_INDICATOR_SETUP_STYLES,
  maxWidth: `calc(${MODAL_SIZES.WIDTH.dimension + MODAL_SIZES.WIDTH.suffix} - ${
    MODAL_SIZES.LEFT_SIDE_WIDTH.dimension + MODAL_SIZES.LEFT_SIDE_WIDTH.suffix
  })`,
};
