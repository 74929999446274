import { apiAction } from "../../api";
import { SERVICE_URLS } from "../../../../globals/service-urls";
import { generateParams } from "../../../../helpers/generate-params";
import fallbackify from "../../../../helpers/fallbackify";
import { INDICATOR_GROUP_CONTROL_TYPES_BY_ID } from "../../../../components/map/globals";
// import { DEFAULT_PAGE_LIMIT } from "../../../globals/settings";
// ? TYPES:
import { VantageData } from "../../../types/maps-vantage";
import { ApiCallback, ApiAction } from "../../../types/api";

// import { PaginatedParams } from "../../../types/paginated-params";

const VERSION = 1;

export const getVantageInitial = ({ vecId }: { vecId: number }, callbacks: ApiCallback<VantageData>) => {
  const params = { idVec: vecId };
  return apiAction({
    method: "GET",
    url: `${SERVICE_URLS["vantage-service"]}${VERSION}/vantage/data${generateParams(params)}`,
    ...callbacks,
    transformResponse: (
      response: Omit<VantageData, "filterTypeId" | "indicatorGroupControlType"> & { filterTypeId?: number }
    ) => {
      const defaultIdFilterType = 1;
      return {
        ...response,
        indicatorGroupControlType: fallbackify(INDICATOR_GROUP_CONTROL_TYPES_BY_ID, defaultIdFilterType)[
          response.filterTypeId as number | "undefined"
        ],
        filterTypeId: response.filterTypeId || defaultIdFilterType,
      };
    },
  });
};

export const getVantageMarkers = (
  { vecId }: { vecId: number },
  { filters }: { filters: Pick<VantageData, "idVantage" | "indicatorGroups"> },
  callbacks: ApiCallback
): ApiAction => {
  const params = { idVec: vecId };
  return apiAction({
    method: "POST",
    data: filters,
    url: `${SERVICE_URLS["vantage-service"]}${VERSION}/vantage/data${generateParams(params)}`,
    ...callbacks,
  });
};
