import { isBlue, isGray } from "./brag-helpers";

import { IndicatorRegionReport } from "../types/indicator";
import { BRAG } from "../types/indicator-threshold";
// import IndicatorNew from "../types/indicator-new";
// import { IndicatorDisplayOption } from "../types/generic";

export { isGray, isBlue };

export const BRAG_BG_COLOUR_MAPPING: any = {
  "#132e3d": "black",
  "#212a41": "black",
  "#43d682": "green",
  "#00ca72": "green",
  "#fda31f": "amber",
  "#fda136": "amber",
  "#e91b4d": "red",
  "#e0394e": "red",
  "#2272bb": "blue",
  "#eee": "white", // error (looks gray)
  "#eeeeee": "white", // error alternative
  "#fff": "white",
  "#ffffff": "white",
  white: "white",
  green: "green",
  amber: "amber",
  red: "red",
  black: "black",
  blue: "blue",
};

export const BRAG_PRESSURE_LEVEL_MAPPING: any = {
  "-1": "white",
  0: "gray",
  1: "green",
  2: "amber",
  3: "red",
  4: "black",
  5: "white", // just in case
};

export const PRESSURE_LEVEL_BRAG_MAPPING: any = {
  gray: 0,
  green: 1,
  amber: 2,
  red: 3,
  black: 4,
  white: -1,
};

// ! same as above but different dto
// ! Used in: Reports
export const calculateNewIndicatorColourByThreshold = (
  indicator: IndicatorRegionReport,
  input: string | number | null,
  checkIfGrayedOut = false
): BRAG => {
  if (input === null || input === undefined || input === "") {
    return null;
  }
  if (checkIfGrayedOut) {
    // TODO:
    // check if indicator is grayedOut
  }
  if (isBlue(indicator, (indicator as any).colorCodeBg || (indicator as any).valueColorCodeBg)) {
    return "blue";
  }
  if (!indicator.thresholdList || indicator.thresholdList.length === 0) {
    return "white";
  }
  const value: number = typeof input === "number" ? input : parseFloat(input);
  return indicator.thresholdList
    .filter((threshold: any) => {
      const beginValue =
        threshold.activationBeginValue === undefined ? threshold.beginValue : threshold.activationBeginValue;
      const endValue = threshold.activationEndValue === undefined ? threshold.endValue : threshold.activationEndValue;
      const b = beginValue === null || beginValue === undefined ? -Infinity : beginValue;
      const e = endValue === null || endValue === undefined ? Infinity : endValue;
      return value >= b && value <= e;
    })
    .reduce((_: BRAG, threshold: any) => {
      return (threshold.idColorCode?.name || threshold.colorName || "white").toLowerCase();
    }, null);
  //return "gray";
  // TODO: return white / null - gray only for system dashboard
};
