import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { Toast } from "@tscore/react-components";
import * as serviceWorker from "../serviceWorker";

const NewVersionNotification = () => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  useEffect(() => {
    const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
      registration && setWaitingWorker(registration.waiting);
      setNewVersionAvailable(true);
    };
    if (process.env.NODE_ENV === "production") {
      // console.log("registering on update");
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    }
  }, [setNewVersionAvailable, setWaitingWorker]);
  useEffect(() => {
    const updateServiceWorker = (e?: any) => {
      e && e.preventDefault();
      if (waitingWorker) {
        const loaderEl = document.getElementById("new-version-available-loader");
        if (loaderEl) {
          loaderEl.style.display = "block";
        }
        waitingWorker.postMessage({ type: "SKIP_WAITING" });
        waitingWorker.addEventListener("statechange", (e) => {
          if ((e.target as any).state === "activated") {
            window.location.reload();
            // # https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated
            // window.location.replace(window.location.href);
          }
        });
      }
      setTimeout(() => {
        const errorEl = document.getElementById("new-version-available-error");
        if (errorEl) {
          errorEl.style.display = "block";
        }
      }, 9000);
    };
    if (newVersionAvailable) {
      // eslint-disable-next-line
      console.log("New Version Available");
      setNewVersionAvailable(false);
      Toast(
        {
          title: i18n.t("toastsMisc:new-version-available.title", { defaultValue: "A new version was released" }),
          body: (
            <span>
              <span id="new-version-available-loader">
                <i className="l" />
              </span>
              <button autoFocus onClick={updateServiceWorker} aria-labelledby="new-version-available-text"></button>
              <a id="new-version-available-text" aria-hidden="true" href="#!refresh" onClick={updateServiceWorker}>
                {i18n.t("toastsMisc:new-version-available.body", { defaultValue: "Click here to update" }) as string}
              </a>
              <span id="new-version-available-error">
                <span id="new-version-available-error-title">Update failed</span>
                <span>
                  - To fix this, please completely close the application (including all tabs/windows) and then reopen
                  it.
                </span>
              </span>
            </span>
          ),
        },
        "info",
        {
          autoClose: false,
          closeOnClick: false,
          toastId: "new-version-available-id",
          className: "newVersionToast",
          draggable: false,
          closeButton: false,
          position: "top-center",
        }
      );
    }
  }, [newVersionAvailable, waitingWorker, setNewVersionAvailable]);
  return null;
};

export default NewVersionNotification;
