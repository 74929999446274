import React from "react";
import { withTranslation } from "react-i18next";
import { Icon, Tippy } from "@tscore/react-components";
// ? TYPES:
import { TFunction } from "i18next";

interface PtlToggleProps {
  id: number;
  enabled?: boolean;
  expanded: boolean;
  onToggle: (id: number) => void;
  t: TFunction;
}

const PtlToggleWoT: React.FC<PtlToggleProps> = ({ enabled, id, expanded, t, onToggle }) => {
  if (!enabled) {
    return null;
  }
  return (
    <Tippy content={t("tooltipTogglePTL", { context: expanded ? "ON" : "OFF" })} placement="left">
      <button
        className="bare-btn ptl-btn__action"
        // id={`ptlToggleBtn_${id}`}
        aria-controls={`ptlDropdownRow_${id}`}
        aria-expanded={expanded}
        type="button"
        onClick={() => onToggle(id)}>
        <span className="ptl-btn__action__icon-wrapper">
          <Icon className="ptl-btn__action__icon">groups</Icon>
          <span className="ptl-btn__action__label">{t("PTL")}</span>
        </span>
        <Icon className="ptl-btn__action__chevron">expand_more</Icon>
      </button>
    </Tippy>
  );
};

export const PtlToggle = withTranslation("ptl")(PtlToggleWoT);
