import { STATIC_BRAGS } from "@tscore/react-components";
// ? TYPES:
import Indicator, { IndicatorPersonalised, AnyIndicator } from "../types/indicator";
// import IndicatorNew from "../types/indicator-new";
import { IndicatorDisplayOption } from "../types/generic";
import { TBragAccents } from "@tscore/react-components";

const BLUE_BG_LIST = ["#2272bb", "blue"];

export const isGray = (indicator: Pick<IndicatorPersonalised, "greyOut"> & { [key: string]: any }) =>
  !!indicator.greyOut;

export const isBlue = (
  indicator: {
    displayOption?: IndicatorDisplayOption;
    [key: string]: any;
  },
  colorCodeBg?: string | undefined
) => indicator.displayOption === 1 || BLUE_BG_LIST.includes(colorCodeBg || "");

export const findMatchingThreshold = (indicator: Pick<AnyIndicator, "thresholdList">, value: number) => {
  if (!indicator.thresholdList || indicator.thresholdList.length === 0) {
    return undefined;
  }
  return indicator.thresholdList.find((threshold) => {
    const b = threshold.beginValue === null || threshold.beginValue === undefined ? -Infinity : threshold.beginValue;
    const e = threshold.endValue === null || threshold.endValue === undefined ? Infinity : threshold.endValue;
    return value >= b && value <= e;
  });
};

export const generateBragAccents = (
  indicator: AnyIndicator,
  shouldGrayOut = false,
  keys: {
    valueColorCodeBg: keyof AnyIndicator;
    valueColorCodeFg: keyof AnyIndicator;
  } = {
    valueColorCodeBg: "valueColorCodeBg",
    valueColorCodeFg: "valueColorCodeFg",
  }
): TBragAccents => {
  if (shouldGrayOut === true && isGray(indicator)) {
    return STATIC_BRAGS.GRAY;
  }
  const bg = indicator[keys.valueColorCodeBg] as string | undefined;
  if (isBlue(indicator, bg)) {
    return STATIC_BRAGS.BLUE;
  }
  if (bg) {
    return { "--accent": bg, "--colour": indicator[keys.valueColorCodeFg] as string };
  }
  return STATIC_BRAGS.WHITE;
};

export const findIndicatorAccentsByValue = (
  indicator: Indicator,
  input: string | null,
  checkIfGrayedOut = false
): TBragAccents => {
  if (input === null || input === undefined || input === "") {
    return {};
  }
  if (checkIfGrayedOut && isGray(indicator)) {
    // probably wont be used
    return STATIC_BRAGS.GRAY;
  }
  if (isBlue(indicator, indicator.valueColorCodeBg)) {
    return STATIC_BRAGS.BLUE;
  }
  if (!indicator.thresholdList || indicator.thresholdList.length === 0) {
    return STATIC_BRAGS.WHITE;
  }
  const value: number = parseFloat(input);
  const threshold = findMatchingThreshold(indicator, value);
  if (threshold) {
    return threshold.colorCodeBg
      ? { "--accent": threshold.colorCodeBg, "--colour": threshold.colorCodeFg }
      : STATIC_BRAGS.WHITE;
  }
  return {};
};
