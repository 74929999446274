import React from "react";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { TFunction } from "i18next";

const CIRCLE_STYLES = {
  width: "16px",
  height: "16px",
  borderRadius: "50%",
  verticalAlign: "middle",
};

export const MapLegendDumb: React.FC<{ t: TFunction }> = ({ t }) => {
  const LEGEND_LIST = [
    {
      label: t("legend.0"),
      colour: "green",
    },
    {
      label: t("legend.1"),
      colour: "amber",
    },
    {
      label: t("legend.2"),
      colour: "red",
    },
    {
      label: t("legend.3"),
      colour: "black",
    },
    {
      label: t("legend.4"),
      colour: "gray",
    },
  ];
  return (
    <aside className="box map-overlay" style={{ bottom: "24px", right: "64px" }}>
      <header>
        <h3>{t("LHE Opel Status")}</h3>
      </header>
      <section style={{ margin: "-4px 0" }}>
        {LEGEND_LIST.map((item) => (
          <div key={item.label} style={{ margin: "4px 0" }}>
            <span
              style={{ ...CIRCLE_STYLES, "--accent": `var(--brag-${item.colour})` } as Style}
              className="brag mr8"></span>
            <span className="v-mid" style={{ display: "inline-block" }}>
              {item.label}
            </span>
          </div>
        ))}
      </section>
    </aside>
  );
};

export const MapLegend = withTranslation(["regionHome"])(MapLegendDumb);
