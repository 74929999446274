import React from "react";
import { BragWithTag } from "@tscore/react-components";
import { generateBragAccents, isGray } from "../../../helpers/brag-helpers";
import { IndicatorExtendedSmart, IndicatorSystem } from "../../../types/indicator";

interface IndicatorTableBragProps {
  indicator: IndicatorExtendedSmart<IndicatorSystem>;
}

export const IndicatorTableBrag: React.FC<IndicatorTableBragProps> = ({ indicator }) => {
  return (
    <BragWithTag
      className="pointer"
      accents={generateBragAccents(indicator, false)}
      isGreyWithTag={isGray(indicator)}
      tooltipDisabled={true}>
      {indicator.readableValue ?? indicator.value}
    </BragWithTag>
  );
};
