// ? TYPES:
import { IntegrationListItem } from "../../../../store/types/integrations-state";
import { SystemDashboardTable } from "../../../../types/system-dashboard-table";

export const rowHasPtl = (
  indicatorList: SystemDashboardTable["rowList"][0]["indicatorList"],
  allPtlList: IntegrationListItem[]
) => {
  return indicatorList.some(
    (indicator) => indicator && allPtlList.find((ptl) => ptl.indicatorId === indicator.indicatorId)
  );
};
