import IntegrationsService from "./integrations.service";
import { cancelRequest } from "../../../helpers/cancel-request";
import { changeToLastPageIfNeeded } from "../../utils/change-to-last-page-if-needed";
import { IntegrationPlot, IntegrationTypePlot } from "../../reducers/integrations/consts";
// ? TYPES:
import { ApiAction } from "../../types/api";
import { PaginatedSort } from "../../../types/paginated-params";
import { TIntegrationKey, TIntegrationTypeKey } from "../../reducers/integrations/consts";
import { IntegrationsListResponse, IntegrationStateByType } from "../../types/integrations-state";
import { ApplicationState } from "../../reducers";

const getIntegrationIds = (integration: TIntegrationKey, integrationType: TIntegrationTypeKey) => ({
  integration,
  integrationType,
  integrationId: IntegrationPlot[integration],
  integrationTypeId: IntegrationTypePlot[integrationType],
});

const getIntegrationState = (getState: () => ApplicationState, integration: TIntegrationKey) => {
  return getState().integrationsReducer[integration] as IntegrationStateByType<TIntegrationKey>;
};

const getIntegrationTypeState = (
  getState: () => ApplicationState,
  integration: TIntegrationKey,
  integrationType: TIntegrationTypeKey
) => {
  return getIntegrationState(getState, integration)[integrationType];
};

const EMPTY_GET_RESPONSE = {
  meta: { contractList: [] },
  page: 1,
  limit: 1,
  results: [],
  totalRecords: 0,
};

const getTableauDashboardList = (
  integration: TIntegrationKey,
  integrationType: TIntegrationTypeKey,
  dynamicId: number,
  data: IntegrationsListResponse
) => ({
  type: "GET_TABLEAU_DASHBOARD_LIST",
  integration,
  integrationType,
  dynamicId,
  data,
});

const getTableauList = (
  integration: TIntegrationKey,
  integrationType: TIntegrationTypeKey,
  dynamicId: number,
  data: IntegrationsListResponse
) => ({
  type: "GET_TABLEAU_INTEGRATION_LIST",
  integration,
  integrationType,
  dynamicId,
  data,
});

const getPtlDashboardIndicatorList = (
  integration: TIntegrationKey,
  integrationType: TIntegrationTypeKey,
  dynamicId: number,
  data: IntegrationsListResponse
) => ({
  type: "GET_PTL_DASHBOARD_INDICATOR_LIST",
  integration,
  integrationType,
  dynamicId,
  data,
});

const getPtlDashboardList = (
  integration: TIntegrationKey,
  integrationType: TIntegrationTypeKey,
  dynamicId: number,
  data: IntegrationsListResponse
) => ({
  type: "GET_PTL_DASHBOARD_LIST",
  integration,
  integrationType,
  dynamicId,
  data,
});

export const fetchTableauList = (
  contractId: number,
  integrationType: TIntegrationTypeKey,
  page = 1,
  query?: string,
  sort?: PaginatedSort,
  lastPageCallback?: (page: number) => void
): ApiAction => {
  cancelRequest("GET_TABLEAU_INTEGRATION_LIST");
  const { integration, integrationId, integrationTypeId } = getIntegrationIds("TABLEAU", integrationType);
  const contractIds = [contractId];
  return IntegrationsService.getIntegrationList(
    { integrationId, integrationTypeId, page, search: query, sort, contractIds },
    {
      label: "GET_TABLEAU_INTEGRATION_LIST",
      onSuccess: (response) => {
        changeToLastPageIfNeeded(lastPageCallback, page, response);
        return getTableauList(integration, integrationType, contractId, response);
      },
      // onFailure: () => console.log("Error occured loading articles"),
      other: { integration, integrationType, dynamicId: contractId },
    }
  );
};

const baseFetchDashboardListType = (
  integration: TIntegrationKey,
  dashboardId: number,
  integrationType: TIntegrationTypeKey,
  label: string,
  successFn: typeof getTableauDashboardList
) => {
  cancelRequest(label);
  const { integrationId, integrationTypeId } = getIntegrationIds(integration, integrationType);
  const dashboardIds = [dashboardId];
  return IntegrationsService.getIntegrationList(
    {
      integrationId,
      integrationTypeId,
      dashboardIds,
      disablePagination: true,
    },
    {
      label: label,
      onSuccess: (response) => successFn(integration, integrationType, dashboardId, response),
      onFailure: (_err, code) => {
        if (code === 403) {
          return successFn(integration, integrationType, dashboardId, EMPTY_GET_RESPONSE);
        }
      },
      other: { integration, integrationType, dynamicId: dashboardId },
    }
  );
};

const baseFetchDashboardListIfNeededType = (
  integration: TIntegrationKey,
  dashboardId: number,
  integrationType: TIntegrationTypeKey,
  successFn: typeof fetchTableauDashboardList
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    // const { integration } = getIntegrationIds(integration, integrationType);
    const state = getIntegrationTypeState(getState, integration, integrationType);
    const dashboardState = state[dashboardId] || state[-1];
    if (Date.now() >= dashboardState.invalidateTimestamp) {
      dispatch(successFn(dashboardId, integrationType));
    }
  };
};

const fetchTableauDashboardList = (dashboardId: number, integrationType: TIntegrationTypeKey): ApiAction => {
  return baseFetchDashboardListType(
    "TABLEAU",
    dashboardId,
    integrationType,
    "GET_TABLEAU_DASHBOARD_LIST",
    getTableauDashboardList
  );
};

export const fetchTableauDashboardListIfNeeded = (dashboardId: number, integrationType: TIntegrationTypeKey) => {
  return baseFetchDashboardListIfNeededType("TABLEAU", dashboardId, integrationType, fetchTableauDashboardList);
};

const fetchPtlDashboardIndicatorList = (dashboardId: number, integrationType: TIntegrationTypeKey): ApiAction => {
  return baseFetchDashboardListType(
    "PTL",
    dashboardId,
    integrationType,
    "GET_PTL_DASHBOARD_INDICATOR_LIST",
    getPtlDashboardIndicatorList
  );
};

export const fetchPtlDashboardIndicatorListIfNeeded = (dashboardId: number, integrationType: TIntegrationTypeKey) => {
  return baseFetchDashboardListIfNeededType("PTL", dashboardId, integrationType, fetchPtlDashboardIndicatorList);
};

const fetchPtlDashboardList = (dashboardId: number, integrationType: TIntegrationTypeKey): ApiAction => {
  return baseFetchDashboardListType("PTL", dashboardId, integrationType, "GET_PTL_DASHBOARD_LIST", getPtlDashboardList);
};

export const fetchPtlDashboardListIfNeeded = (dashboardId: number, integrationType: TIntegrationTypeKey) => {
  return baseFetchDashboardListIfNeededType("PTL", dashboardId, integrationType, fetchPtlDashboardList);
};
