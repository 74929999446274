/**
 * Creates a proxy object that provides fallback values for missing properties
 * @param obj - The source object to create a proxy for
 * @param optionsOrDefaultKey - How to handle missing properties. Can be either:
 *   - A key from the source object (will return that key's value)
 *   - An object with `defaultValue` (will return this value)
 *   - An object with `transformDefaultProperty` function (will call this with the missing key)
 * @returns A proxy of the original object that handles missing properties according to the options
 * @example
 * const data = { a: 1, b: 2 };
 *
 * const p1 = fallbackify(data, "a");
 * const p2 = fallbackify(data, { defaultValue: 0 });
 * const p3 = fallbackify(data, { transformDefaultProperty: (key) => `${key} not found` });
 *
 * console.log(p1.a); // 1
 * console.log(p1.nonexistent); // 1
 * console.log(p2.nonexistent); // 0
 * console.log(p3.nonexistent); // "Key nonexistent not found!"
 */

type FallbackifyOptions<T> =
  | keyof T
  | { defaultValue: unknown }
  | { transformDefaultProperty: (key: string, obj: T) => unknown };

// Helper type to determine return type based on options
type FallbackifyReturn<T, Options> = Options extends keyof T
  ? T & Record<string, T[keyof T]> // When using a key of T, return type includes that specific value type
  : Options extends { defaultValue: infer D }
  ? T & Record<string, D> // When using defaultValue, return type includes that type
  : Options extends { transformDefaultProperty: (key: string, obj: T) => infer R }
  ? T & Record<string, R> // When using transform, return type includes transformed type
  : T & Record<string, undefined>; // Default case

export default function fallbackify<T extends Record<string, any>, O extends FallbackifyOptions<T>>(
  obj: T,
  optionsOrDefaultKey?: O
): FallbackifyReturn<T, O> {
  const defaultValueHandler: ProxyHandler<T> = {
    get: (target, property: string) => {
      // Check if the property exists in the object
      if (property in target) {
        return target[property as keyof T];
      }

      if (!optionsOrDefaultKey) {
        return undefined;
      }

      if (typeof optionsOrDefaultKey !== "object") {
        // Here optionsOrDefaultKey is keyof T
        return target[optionsOrDefaultKey as keyof T];
      }

      // Type guard for transformDefaultProperty
      if (
        "transformDefaultProperty" in optionsOrDefaultKey &&
        typeof optionsOrDefaultKey.transformDefaultProperty === "function"
      ) {
        return optionsOrDefaultKey.transformDefaultProperty(property, target);
      }

      // Type guard for defaultValue
      if ("defaultValue" in optionsOrDefaultKey) {
        return optionsOrDefaultKey.defaultValue;
      }

      return undefined;
    },
  };

  return new Proxy(obj, defaultValueHandler) as FallbackifyReturn<T, O>;
}
