import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import debounce from "lodash/debounce";

import { fetchUpdateCurrentVantageFilters, updateVantageFiltersLocal } from "../../../../store/actions/maps";
// import { Select } from "@tscore/react-components";
import { FilterSelect } from "../../../../components/map/shared/filter-select";
import { useTranslation } from "react-i18next";
import { useActiveTabTimerKey } from "../../../../hooks/use-active-tab-timer-key";
// ? TYPES:
import { ApplicationState } from "../../../../store/reducers";
import { VantageIndicatorGroup } from "../../../../store/types/maps-vantage";

export type TFilterName = "indicatorGroups" | "autorefresh";

const FILTER_UPDATE_DEBOUNCE_MS = 800;
const AUTO_REFRESH_MINS = 10;

const SelectLoading = () => <div style={{ height: "3.2rem", margin: 0 }} className="loading-element"></div>;
const FiltersLoading = ({
  isFiltersLoading,
  lastUpdatedName,
  currentName,
}: {
  isFiltersLoading: boolean;
  currentName: TFilterName | undefined;
  lastUpdatedName: TFilterName | undefined;
}) =>
  isFiltersLoading && lastUpdatedName !== currentName ? (
    <i className="l inline" style={{ marginLeft: "0.8rem" }} />
  ) : null;

export const Filters: React.FC<{ id: number }> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [refreshKey, [id]] = useActiveTabTimerKey<[number]>({
    interval: AUTO_REFRESH_MINS * 60000,
    resetKey: [props.id],
  });
  const [isFiltersLoading, setIsFiltersLoading] = useState<boolean>(false);
  const state = useSelector((state: ApplicationState) => state.mapsReducer);
  const { isFetching, vantage } = state;
  const { indicatorGroups, indicatorGroupControlType } = vantage[id] || vantage[-1];

  useEffect(() => {
    if (isFetching["UPDATE_VANTAGE_FILTERS"] === false) {
      setIsFiltersLoading(false);
    }
  }, [setIsFiltersLoading, isFetching]);
  const isFetchingInitial = isFetching["GET_VANTAGE_INITIAL"];
  const debouncedUpdate = useCallback(
    debounce((name: TFilterName) => dispatch(fetchUpdateCurrentVantageFilters(id, name)), FILTER_UPDATE_DEBOUNCE_MS),
    [id]
  );
  useEffect(() => {
    if (refreshKey > 0) {
      debouncedUpdate("autorefresh");
    }
  }, [debouncedUpdate, refreshKey]);

  const getSelectValue = (selected: VantageIndicatorGroup | VantageIndicatorGroup[] | null) => {
    if (!selected) return [];
    if (Array.isArray(selected)) return selected;
    return [selected];
  };
  const onSelectChange = (selectedOriginal: VantageIndicatorGroup | VantageIndicatorGroup[] | null, action: any) => {
    const selected = getSelectValue(selectedOriginal);
    dispatch(updateVantageFiltersLocal(selected, action.name, action, id));
    if (selected && selected.length > 0) {
      setIsFiltersLoading(true);
      debouncedUpdate(action.name);
    } else {
      setIsFiltersLoading(false);
      debouncedUpdate.cancel();
    }
  };
  const onMenuClose = () => {
    // maybe update if needed etc
  };
  const indicatorGroupsValue = indicatorGroups.filter((item) => item.selected === true) as any;
  return (
    <aside className="box map-overlay" style={{ top: "24px", right: "24px" }}>
      <header>
        <h3>
          <span>{t("map:Filter options")}</span>
          <span>
            {isFetching["UPDATE_VANTAGE_FILTERS"] && !isFiltersLoading && <i className="l inline" />}
            {/* {(isFetching["UPDATE_ELECTIVE_FILTERS"] || isFiltersLoading) && <i className="l inline" />} */}
          </span>
        </h3>
      </header>
      <section className={isFetchingInitial ? "isFetching" : ""} style={{ width: "20vw", maxWidth: "330px" }}>
        <div className="form">
          <div className="form-row">
            <div className="form-field">
              <h4>
                {t("map:Indicator Groups")}
                <FiltersLoading
                  isFiltersLoading={isFiltersLoading}
                  lastUpdatedName={undefined}
                  currentName="indicatorGroups"
                />
              </h4>
            </div>
          </div>
          <div className="form-row" style={{ marginTop: 0 }}>
            <div className="form-field">
              {isFetchingInitial ? (
                <SelectLoading />
              ) : (
                <FilterSelect
                  selectType={indicatorGroupControlType}
                  maxOptionsCollapsed={1}
                  name={"indicatorGroups"}
                  placeholder={t("map:numberSelected_zero")}
                  placeholderKey=""
                  options={indicatorGroups}
                  onChange={onSelectChange as () => void}
                  optionValueName="idIndicatorGroup"
                  value={indicatorGroupsValue}
                  className={classNames("indicator-group__select--seamless", {
                    "has-error": !isFiltersLoading && indicatorGroupsValue.length === 0,
                  })}
                  tabIndex="1"
                  onMenuClose={onMenuClose}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </aside>
  );
};
