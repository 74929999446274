import MapsService from "./maps.service";
import { cancelRequest } from "../../../helpers/cancel-request";
import { apiError, FAKE_AXIOS_ERROR } from "../api";
import isEqual from "lodash/isEqual";

// ? TYPES:
import { ApiAction, PaginatedResponse } from "../../types/api";
import { MapsHospital, MapsState, NhsrChildResponse, NhsrParentResponse } from "../../types/maps-state";
import { ChildFeature } from "../../../types/google-map";
import { ElectiveData } from "../../types/maps-elective";
import { VantageData, VantageIndicatorGroup } from "../../types/maps-vantage";
// import { PaginatedSort } from "../../../types/paginated-params";
import { ApplicationState } from "../../../store/reducers";
// import { Cancelable } from "../../../types/generic";

const getResilienceBoundary = (data: PaginatedResponse<MapsState["resilienceHome"]["boundary"]["child"]>) => ({
  type: "GET_RESILIENCE_BOUNDARY",
  data,
});

export const fetchResilienceBoundary = (): ApiAction => {
  cancelRequest("GET_RESILIENCE_BOUNDARY");
  return MapsService.getResilienceBoundary({
    label: "GET_RESILIENCE_BOUNDARY",
    onSuccess: getResilienceBoundary,
    // onFailure: () => console.log("Error occured loading articles"),
  });
};

const getResilienceHospitals = (data: PaginatedResponse<MapsHospital>) => ({
  type: "GET_RESILIENCE_HOSPITALS",
  data,
});

export const fetchResilienceHospitals = (): ApiAction => {
  cancelRequest("GET_RESILIENCE_HOSPITALS");
  return MapsService.getResilienceHospitals({
    label: "GET_RESILIENCE_HOSPITALS",
    onSuccess: getResilienceHospitals,
    // onFailure: () => console.log("Error occured loading articles"),
  });
};

const getNhsrParentBoundary = (data: PaginatedResponse<NhsrParentResponse[]>) => ({
  type: "GET_NHSR_PARENT_BOUNDARY",
  data,
});

export const fetchNhsrParentBoundary = (): ApiAction => {
  cancelRequest("GET_NHSR_PARENT_BOUNDARY");
  return MapsService.getNhsrParentBoundary({
    label: "GET_NHSR_PARENT_BOUNDARY",
    onSuccess: getNhsrParentBoundary,
  });
};

const getNhsrChildBoundary = (data: PaginatedResponse<NhsrChildResponse>) => ({
  type: "GET_NHSR_CHILD_BOUNDARY",
  data,
});

export const fetchNhsrChildBoundary = (): ApiAction => {
  cancelRequest("GET_NHSR_CHILD_BOUNDARY");
  return MapsService.getNhsrChildBoundary({
    label: "GET_NHSR_CHILD_BOUNDARY",
    onSuccess: getNhsrChildBoundary,
  });
};

const getNhsrHospitals = (data: PaginatedResponse<MapsHospital>) => ({
  type: "GET_NHSR_HOSPITALS",
  data,
});

// export const fetchNhsrHospitals = (): ApiAction => {
//   cancelRequest("GET_NHSR_HOSPITALS");
//   return MapsService.getNhsrHospitals({
//     label: "GET_NHSR_HOSPITALS",
//     onSuccess: getNhsrHospitals,
//     // onFailure: () => console.log("Error occured loading articles"),
//   });
// };

export const fetchNhsrHospitals = () => {
  return (dispatch: any, getState: () => ApplicationState) => {
    // ! Check if user has access to NHSR first before asking hospitals, if he doesn't create fake error 401
    const hasNhsrAccess = getState().credentialsReducer.app_user_info.productList.includes("Region");
    cancelRequest("GET_NHSR_HOSPITALS");
    if (hasNhsrAccess) {
      dispatch(
        MapsService.getNhsrHospitals({
          label: "GET_NHSR_HOSPITALS",
          onSuccess: getNhsrHospitals,
          // onFailure: () => console.log("Error occured loading articles"),
        })
      );
    } else {
      dispatch(apiError("GET_NHSR_HOSPITALS", "Unknown", FAKE_AXIOS_ERROR({ code: 401 })));
    }
  };
};

export const setResilienceBounds = (google: any) => ({
  type: "SET_RESILIENCE_BOUNDS",
  google,
});

export const setNhsrBounds = (google: any) => ({
  type: "SET_NHSR_BOUNDS",
  google,
});

// Elective:
export const setElectiveBounds = (google: any, id: number) => ({
  type: "SET_ELECTIVE_BOUNDS",
  google,
  id,
});

const getElectiveInitial = (data: ElectiveData, id: number) => ({
  type: "GET_ELECTIVE_INITIAL",
  data,
  id,
});

const getElectiveChildBoundary = (data: ChildFeature[], id: number) => ({
  type: "GET_ELECTIVE_CHILD_BOUNDARY",
  data,
  id,
});

const fetchElectiveChildBoundary = (ccgNames: string[], id: number) => {
  return MapsService.getSlamdunkCcgBoundaries(
    { ccgNames, isCombined: false },
    {
      label: "GET_ELECTIVE_CHILD_BOUNDARY",
      onSuccess: (data) => getElectiveChildBoundary(data, id),
      other: { id },
    }
  );
};

export const fetchElectiveInitial = (id: number) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    cancelRequest("GET_ELECTIVE_INITIAL");
    dispatch(
      MapsService.getElectiveInitial(
        { vecId: id },
        {
          label: "GET_ELECTIVE_INITIAL",
          onSuccess: (data: ElectiveData) => {
            const ccgNames = data.ccgs.map((ccg) => ccg.ccgName);
            const prevState = getState().mapsReducer;
            const prevModuleState = prevState.vantage[id] || prevState.vantage["-1"];
            const prevNames = prevModuleState.ccgs.map((ccg) => ccg.ccgName);
            if (ccgNames.length > 0 && !isEqual(prevNames, ccgNames)) {
              dispatch(fetchElectiveChildBoundary(ccgNames, id));
            }

            return getElectiveInitial(data, id);
          },
          other: { id },
        }
      )
    );
  };
};

const updateElectiveFilters = (
  data: ElectiveData,
  name: "specialities" | "segments" | "indicatorGroups" | "autorefresh",
  id: number
) => ({
  type: "UPDATE_ELECTIVE_FILTERS",
  data,
  name,
  id,
});

// export const fetchUpdateElectiveFilters = (filters: Omit<ElectiveData, "ccgs" | "markers">) => {
//   cancelRequest("UPDATE_ELECTIVE_FILTERS");
//   return MapsService.getElectiveMarkers(
//     { filters },
//     { label: "UPDATE_ELECTIVE_FILTERS", onSuccess: updateElectiveFilters }
//   );
// };

export const fetchUpdateCurrentElectiveFilters = (
  name: "specialities" | "segments" | "indicatorGroups" | "autorefresh",
  id: number
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    cancelRequest("UPDATE_ELECTIVE_FILTERS");
    const { segments, specialities, indicatorGroups } = getState().mapsReducer.elective[id];
    const areAnyFiltersEmpty = [segments, specialities, indicatorGroups].some((f) => !f.some((x) => x.selected));
    if (areAnyFiltersEmpty) {
      return;
    }
    dispatch(
      MapsService.getElectiveMarkers(
        { vecId: id },
        { filters: { segments, specialities, indicatorGroups } },
        {
          label: "UPDATE_ELECTIVE_FILTERS",
          onSuccess: (response: ElectiveData) => updateElectiveFilters(response, name, id),
          other: { id },
        }
      )
    );
  };
};

export const updateElectiveFiltersLocal = (
  selected: any[] | null,
  name: "segments" | "indicatorGroups" | "specialities",
  action: any,
  id: number
) => ({
  type: "UPDATE_ELECTIVE_FILTERS_LOCAL",
  selected: selected === null ? [] : selected,
  name,
  action,
  id,
});

// Vantage:
export const setVantageBounds = (google: any, id: number) => ({
  type: "SET_VANTAGE_BOUNDS",
  google,
  id,
});

export const vantageCleanup = (id: number) => ({
  type: "VANTAGE_CLEANUP",
  id,
});

const getVantageInitial = (data: VantageData, id: number) => ({
  type: "GET_VANTAGE_INITIAL",
  data,
  id,
});

const getVantageChildBoundary = (data: ChildFeature[], id: number) => ({
  type: "GET_VANTAGE_CHILD_BOUNDARY",
  data,
  id,
});

const fetchVantageChildBoundary = (ccgNames: string[], id: number) => {
  return MapsService.getSlamdunkCcgBoundaries(
    { ccgNames, isCombined: false },
    {
      label: "GET_VANTAGE_CHILD_BOUNDARY",
      onSuccess: (data) => getVantageChildBoundary(data, id),
      other: { id },
    }
  );
};

export const fetchVantageInitial = (id: number) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    cancelRequest("GET_VANTAGE_INITIAL");
    dispatch(
      MapsService.getVantageInitial(
        { vecId: id },
        {
          label: "GET_VANTAGE_INITIAL",
          onSuccess: (data: VantageData) => {
            const prevState = getState().mapsReducer;
            const prevModuleState = prevState.vantage[id] || prevState.vantage["-1"];
            const prevNames = prevModuleState.ccgs.map((ccg) => ccg.ccgName);
            const ccgNames = data.ccgs.map((ccg) => ccg.ccgName);
            if (ccgNames.length > 0 && !isEqual(prevNames, ccgNames)) {
              dispatch(fetchVantageChildBoundary(ccgNames, id));
            }
            return getVantageInitial(data, id);
          },
          other: { id },
        }
      )
    );
  };
};

const updateVantageFilters = (
  data: VantageData,
  id: number,
  name: "indicatorGroups" | "autorefresh" = "indicatorGroups"
) => ({
  type: "UPDATE_VANTAGE_FILTERS",
  data,
  name,
  id,
});

export const fetchUpdateCurrentVantageFilters = (
  id: number,
  name: "indicatorGroups" | "autorefresh" = "indicatorGroups"
) => {
  return (dispatch: any, getState: () => ApplicationState) => {
    cancelRequest("UPDATE_VANTAGE_FILTERS");
    const { indicatorGroups } = getState().mapsReducer.vantage[id];
    const areAnyFiltersEmpty = !indicatorGroups.some((x) => x.selected);
    if (areAnyFiltersEmpty) {
      return;
    }
    dispatch(
      MapsService.getVantageMarkers(
        { vecId: id },
        { filters: { indicatorGroups, idVantage: id } },
        {
          label: "UPDATE_VANTAGE_FILTERS",
          onSuccess: (response: VantageData) => updateVantageFilters(response, id, name),
          other: { id },
        }
      )
    );
  };
};

export const updateVantageFiltersLocal = (
  selected: VantageIndicatorGroup[],
  name: "indicatorGroups" = "indicatorGroups",
  action: any,
  id: number
) => ({
  type: "UPDATE_VANTAGE_FILTERS_LOCAL",
  selected,
  name,
  action,
  id,
});
