import React from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "@tscore/react-components";
// ? TYPES:
import { ApplicationState } from "../../../../store/reducers";

interface Props {
  id: number;
}

export const PtlDropdownItems: React.FC<Props> = ({ id }) => {
  const { data: ptlReports } = useSelector(
    (state: ApplicationState) =>
      state.integrationsReducer.PTL.DASHBOARD[id] || state.integrationsReducer.PTL.DASHBOARD[-1]
  );
  return (
    <>
      {ptlReports.map((item) => {
        return (
          <Dropdown.Item
            iconRight="launch"
            key={item.integrationLinkId}
            label={item.name}
            href={item.link}
            target="_blank"
            rel="noopener external"
          />
        );
      })}
    </>
  );
};
