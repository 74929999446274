import React, { useRef, useState } from "react";
// import { Brag } from "@tscore/react-components";
import { BragWithTag } from "@tscore/react-components";
import { generateBragAccents, isGray } from "../../helpers/brag-helpers";
import { showThresholdValue } from "../../helpers/show-threshold-value";
import { formatToLocalString } from "../../lang/date-fns";
import { UPDATED_ON_DATE_TIME_FORMAT } from "./consts";
import { IndicatorGraph } from "../../components/indicator-history/indicator-graph";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import { IndicatorAdvancedTriggers } from "../../components/indicator-advanced-triggers";
// ? TYPES:
import { IndicatorSystem, IndicatorPersonalised, IndicatorExtendedSmart } from "../../types/indicator";
import { ISHExtraParams } from "../../store/types/indicator-history-state";

type Indicator = IndicatorSystem;
// import { SystemDashboardTable } from "../../types/system-dashboard-table";

interface IndicatorModalProps {
  indicator: IndicatorExtendedSmart<Indicator | IndicatorPersonalised>;
  extraISHInfo: ISHExtraParams;
}

export const IndicatorModal: React.FC<IndicatorModalProps> = ({ indicator, extraISHInfo }) => {
  const { t } = useTranslation();
  const triggerElemRef = useRef<HTMLDivElement>(undefined!);
  const [showAdvancedTrigger, setShowAdvancedTrigger] = useState(false);
  // const [advancedTriggerDimensions, setAdvancedTriggerDimensions] = useState<Partial<DOMRect>>({});
  // useLayoutEffect(() => {
  //   if (triggerElemRef.current) {
  //     setAdvancedTriggerDimensions(triggerElemRef.current.getBoundingClientRect());
  //   }
  // }, [triggerElemRef.current, setAdvancedTriggerDimensions]);
  const toggleAdvancedTrigger = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setShowAdvancedTrigger((prev) => !prev);
  };
  const loading = false;
  const valueTimestampFormatted = formatToLocalString(indicator.valueTimestamp, UPDATED_ON_DATE_TIME_FORMAT);
  const isDateIndicator = indicator.dataType === 10;
  const indicatorModalCSS = `
    .indicator-details-modal {
      max-width: ${isDateIndicator ? 48 : 96}% !important;
    }
  `;
  return (
    <div id="indicator-details" data-type={indicator.dataType}>
      <div className="indicator-details__column">
        <header>
          <h4>{t("dashboards:Indicator Details")}</h4>
        </header>
        {loading ? (
          <span>loading</span>
        ) : (
          <article style={{ height: "auto" }}>
            <div className="form form-baseline">
              <section className="form-row">
                <div className="form-field">
                  <label>{t("dashboards:indicatorDetailsLabels.Name")}</label>
                </div>
                <div className="form-field">{indicator.indicatorName}</div>
              </section>
              <section className="form-row">
                <div className="form-field">
                  <label>{t("dashboards:indicatorDetailsLabels.ID")}</label>
                </div>
                <div className="form-field">{indicator.indicatorId}</div>
              </section>
              <section className="form-row">
                <div className="form-field">
                  <label>{t("dashboards:indicatorDetailsLabels.Description")}</label>
                </div>
                <div className="form-field">{indicator.description}</div>
              </section>
              <section className="form-row">
                <div className="form-field">
                  <label>{t("dashboards:indicatorDetailsLabels.Current Value")}</label>
                </div>
                <div className="form-field">
                  <BragWithTag
                    accents={generateBragAccents(indicator, false)}
                    isGreyWithTag={isGray(indicator)}
                    updatedAtText={<Moment fromNow>{indicator.valueTimestamp}</Moment>}>
                    {indicator.readableValue ?? indicator.value}
                  </BragWithTag>
                </div>
              </section>
              <section className="form-row">
                <div className="form-field">
                  <label>{t("dashboards:indicatorDetailsLabels.Comment")}</label>
                </div>
                <div className="form-field">{indicator.comment || <>&ndash;</>}</div>
              </section>
              <section className="form-row">
                <div className="form-field">
                  <label>{t("dashboards:indicatorDetailsLabels.Updated on")}</label>
                </div>
                <div className="form-field">{valueTimestampFormatted}</div>
              </section>
              <section className="form-row">
                <div className="form-field">
                  <label>{t("dashboards:indicatorDetailsLabels.Updated by")}</label>
                </div>
                <div className="form-field">{indicator.updatedBy.fullname}</div>
              </section>
            </div>
          </article>
        )}
        <hr className="mt18" style={{ opacity: 0 }} />
        <div ref={triggerElemRef}>
          <header className="trigger-details__header">
            <div>
              <h4>{t("dashboards:Trigger Details")}</h4>
              {/* <p>{valueTimestampFormatted}</p> */}
            </div>
            <div>
              {indicator.showAnyThresholdDetails && (
                <a href="#!toggle" onClick={toggleAdvancedTrigger}>
                  <span>
                    {t("dashboards:toggleAllTriggerDetails", { context: showAdvancedTrigger ? "HIDE" : "SHOW" })}
                  </span>
                </a>
              )}
            </div>
          </header>
          <table className="table bordered no-vertical-lines main">
            <thead>
              <tr>
                <th className="w1 nowrap ta-center">{t("dashboards:triggerDetailsTable.0")}</th>
                <th>{t("dashboards:triggerDetailsTable.1")}</th>
                <th className="w1 nowrap ta-center">{t("dashboards:triggerDetailsTable.2")}</th>
                <th className="w1 nowrap ta-center">{t("dashboards:triggerDetailsTable.3")}</th>
                <th className="w1 nowrap ta-center">{t("dashboards:triggerDetailsTable.4")}</th>
              </tr>
            </thead>
            <tbody>
              {!indicator.thresholdList || indicator.thresholdList.length === 0 ? (
                <tr>
                  <td colSpan={5}>{t("dashboards:noTriggers")}</td>
                </tr>
              ) : (
                indicator.thresholdList.map((thr, i) => (
                  <tr key={i}>
                    <td className="ta-center">{thr.pressureLevel}</td>
                    <td>{thr.description || <>&ndash;</>}</td>
                    <td className="ta-center">{showThresholdValue(thr.beginValue)}</td>
                    <td className="ta-center">{showThresholdValue(thr.endValue, true)}</td>
                    <td className="ta-center" style={{ padding: "0.3rem 0.4rem" }}>
                      <span
                        className="brag"
                        style={{ width: "80px", "--accent": thr.colorCodeBg, "--colour": thr.colorCodeFg } as Style}>
                        {t("generic:colours." + thr.colorName, { defaultValue: thr.colorName })}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {!isDateIndicator && (
        <div className="indicator-details__column" data-show-triggers={showAdvancedTrigger}>
          <IndicatorAdvancedTriggers
            toggleAdvancedTrigger={toggleAdvancedTrigger}
            triggerElemRef={triggerElemRef}
            // dimensions={advancedTriggerDimensions}
            thresholdDetails={indicator.thresholdDetailsSmart}
          />
          <IndicatorGraph indicators={[indicator]} extraISHInfo={extraISHInfo} />
        </div>
      )}
      <style dangerouslySetInnerHTML={{ __html: indicatorModalCSS }} />
    </div>
  );
};
