import React from "react";
import { withTranslation } from "react-i18next";
// ? TYPES:
import { IntegrationListItem } from "../../../../store/types/integrations-state";
import { IndicatorExtendedSmart, IndicatorSystem } from "../../../../types/indicator";
import { TFunction } from "i18next";

interface PtlRowProps {
  id: number;
  enabled?: boolean;
  expanded?: boolean;
  ptlList: IntegrationListItem<string>[];
  indicatorList: (IndicatorExtendedSmart<IndicatorSystem> | null)[];
  t: TFunction;
}

const PtlRowWoT: React.FC<PtlRowProps> = ({ id, indicatorList, ptlList, expanded, enabled, t }) => {
  if (!enabled) {
    return null;
  }
  const ptlPlot = Object.fromEntries(ptlList.map((item) => [item.indicatorId, item]));
  return (
    <ul className="row ptl__row" role="row" id={`ptlDropdownRow_${id}`} aria-hidden={!expanded}>
      <li className="cell normal-holder" aria-hidden={true} role="cell"></li>
      {indicatorList.map((indicator, index) => {
        const ptl = indicator ? ptlPlot[indicator.indicatorId] : null;
        if (!ptl) {
          return <li key={index} className="cell brag-holder" role="cell" />;
        }
        return (
          <li key={index} className="cell brag-holder ptl-holder" role="cell">
            {/* eslint-disable-next-line react/jsx-no-target-blank*/}
            <a className="btn fullwidth link ptl-btn" target="_blank" href={ptl.link} rel="noopener external">
              {t("View PTL")}
            </a>
          </li>
        );
      })}
      <li className="cell graph-holder" aria-hidden={true} role="cell"></li>
    </ul>
  );
};

export const PtlRow = withTranslation("ptl")(PtlRowWoT);
