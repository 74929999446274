import { accessEnv } from "../../access-env";
// ? TYPES:
import { MapOptions } from "../../types/google-map";

// const DEPLOYMENT = process.env.REACT_APP_DEPLOYMENT_TYPE || "uk";

// export const GOOGLE_MAP_URL = "https://maps.googleapis.com/maps/api/js";

// export const BOUNDS_ZOOM_RELATIVE_TO_MAX = -1;
// export const MAX_ZOOM = 10;

export const INDICATOR_GROUP_CONTROL_TYPES_BY_ID = {
  1: "MULTI_SELECT_DROPDOWN",
  2: "SINGLE_SELECT_DROPDOWN",
} as const;

export type TIndicatorGroupControlType =
  (typeof INDICATOR_GROUP_CONTROL_TYPES_BY_ID)[keyof typeof INDICATOR_GROUP_CONTROL_TYPES_BY_ID];

export const DEFAULT_MAP_STYLES = {
  containerStyle: { position: "relative" },
  style: { width: "100%", height: "100%", position: "relative" },
};

export const defaultMapSettings: MapOptions = {
  zoom: 7,
  disableDefaultUI: true,
  maxZoom: 10,
  minZoom: 7,
  zoomControl: true,
  mapTypeControl: false,
};

const INITIAL_CENTERS: { [key: string]: google.maps.LatLng | google.maps.LatLngLiteral } = {
  uk: {
    lat: 51.5074,
    lng: 0.1278,
  },
  ca: {
    lat: 46.833,
    lng: -71.2126,
  },
};

const MAP_SETTINGS: { [key: string]: MapOptions } = {
  uk: {
    ...defaultMapSettings,
  },
  ca: {
    ...defaultMapSettings,
    minZoom: 3,
  },
};

export const initialCenter: google.maps.LatLng | google.maps.LatLngLiteral =
  INITIAL_CENTERS[accessEnv("DEPLOYMENT_TYPE", "uk")] || INITIAL_CENTERS["uk"];

export const mapSettings: MapOptions = MAP_SETTINGS[accessEnv("DEPLOYMENT_TYPE", "uk")] || MAP_SETTINGS["uk"];
