export function hyphenateLongWords(strings: string[], maxLength: number): string[] {
  if (maxLength < 1) {
    return strings;
  }
  return strings.reduce((final, current) => {
    if (current.length > maxLength) {
      const currentSplit = current.match(new RegExp(".{1," + maxLength + "}", "g"))!.map((s, i) => i === 0 ? s : "-" + s);
      return [...final, ...currentSplit];
    }
    final.push(current);
    return final;
  }, [] as string[]);
}

export function multiLineSplitter(str: string, maxLength: number, hyphenLongerWordsAtChar = 0, treatSlashAsWord = true): string[] {
  const SLASH_CHARACTER = "ƒ";
  const originalString = treatSlashAsWord ? str.replace(/(\S)\/(\S)/g, `$1 ${SLASH_CHARACTER} $2`) : str;
  // hyphenLongerWordsAtChar must be greater than maxLength
  const splitted = hyphenateLongWords(originalString.split(" "), hyphenLongerWordsAtChar);
  const output = splitted.reduce((final, current, i) => {
    if (i === 0) {
      final.push(current);
      return final;
    }
    const n = final.length - 1;
    const prevValue = final[n];
    if (prevValue.length <= maxLength) {
      const newTempValue = prevValue + " " + current;
      if (newTempValue.length <= maxLength) {
        final[n] = newTempValue;
      } else {
        final.push(current);
      }
    } else {
      final.push(current);
    }
    return final;
  }, [] as string[]);
  if (treatSlashAsWord) {
    const slashRegex = new RegExp(`\\s*${SLASH_CHARACTER}\\s*`, "g");
    return output.map((s) => s.replace(slashRegex, "/"));
  }
  return output;
}

// multiLineSplitter.test.ts
// const SAMPLE = ["20/20 OPEL", "Community/Social/Hospice Care", "Community Social Hospice Care", "Test Verylongwordlongwordwordword", "Verylongwordlongwordwordword"];

// const OUT = SAMPLE.map((str) => multiLineSplitter(str, 12, 0));
// console.log(OUT);