import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { editAndFetchUpdateWeight, fetchTables } from "../../../store/actions/system-dashboard";
import { Icon, Tippy, Modal } from "@tscore/react-components";
import { WeightInput } from "../weight-input";
import classNames from "classnames";
import { IndicatorModal } from "../indicator-modal";
import { IndicatorGraph } from "../../../components/indicator-history/indicator-graph";
import { formatToLocalString } from "../../../lang/date-fns";
import { UPDATED_ON_DATE_TIME_FORMAT, INDICATOR_MODAL_GENERAL_PROPS } from "../consts";
import { useTranslation } from "react-i18next";
import { IndicatorTableBrag } from "./indicator-table-brag";
import { PtlRow } from "./ptl/ptl-row";
import { rowHasPtl } from "./ptl/consts";

// ? TYPES:
import { SystemDashboardTable } from "../../../types/system-dashboard-table";
import { IndicatorExtendedSmart, IndicatorSystem } from "../../../types/indicator";
import { RBA } from "../../../store/types/menu-state";
import { PtlToggle } from "./ptl/ptl-toggle";
import { IntegrationListItem } from "../../../store/types/integrations-state";
// import { PtlRow } from "./indicator-table/ptl-row";

type Indicator = IndicatorSystem;

interface IndicatorTableProps {
  rba: RBA[0];
  // canEditWeights?: boolean;
  data: SystemDashboardTable<IndicatorExtendedSmart<IndicatorSystem>>[];
  title: string;
  unitId: number;
  showWeights: boolean;
  systemDashboardId: number; // weight update
  siteId: number; // weight update
  isSavingWeights: { [indicatorId: string]: undefined | { oldWeight: number } };
  isFetchingTable: boolean;
  ptlList: IntegrationListItem[];
}

export const IndicatorTable: React.FC<IndicatorTableProps> = ({
  data,
  rba,
  // title,
  unitId,
  showWeights,
  systemDashboardId,
  siteId,
  isSavingWeights,
  isFetchingTable = false,
  ptlList,
}) => {
  const [activePtlRow, setActivePtlRow] = useState(-1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const canEditWeights = rba.WEIGHT_EDIT;
  const onWeightChange =
    (tableId: number, tableRowId: number) =>
    (e: any, indicator: Indicator): void => {
      if (!canEditWeights) {
        return;
      }
      const { value } = e.target;
      const isValid = /^\d+$/.test(value);
      dispatch(
        editAndFetchUpdateWeight(
          systemDashboardId,
          siteId,
          unitId,
          indicator.indicatorId,
          value,
          tableId,
          tableRowId,
          isValid
        )
      );
      // console.log(value);
    };
  const onPtlRowToggle = (tableRowId: number) => {
    setActivePtlRow((prev) => (prev === tableRowId ? -1 : tableRowId));
  };
  const onRefreshData = (unitId: number) => {
    if (isFetchingTable) {
      return;
    }
    dispatch(fetchTables(systemDashboardId, siteId, unitId));
  };
  return (
    <>
      {data.map((table) => {
        const gridTemplateColumns = `1fr repeat(${table.columnList.length}, minmax(auto, min-content)) auto`;
        return (
          <div key={table.tableId}>
            <div>
              <div
                role="table"
                className="table grid-table bordered no-vertical-lines main shadowed"
                style={{
                  gridTemplateColumns,
                }}
                data-unit-id={unitId}>
                <header className="thead" role="rowgroup">
                  <ul className="row" role="row">
                    <li className="th" role="columnheader">
                      {table.title}
                    </li>
                    {table.columnList.map((heading) => (
                      <li className="th ta-center" role="columnheader" key={heading.tableColumnId}>
                        {heading.title}
                      </li>
                    ))}
                    <li
                      className="th graph-holder refresh-button-holder"
                      role="columnheader"
                      style={{ alignContent: "center" }}>
                      <Tippy content={t("dashboards:tooltipUpdateTableData")} placement="bottom">
                        <button className="bare-btn" onClick={() => onRefreshData(unitId)}>
                          <i
                            aria-hidden={true}
                            className={classNames("material-icons pointer table-refresh-icon", {
                              isRefreshing: isFetchingTable,
                            })}>
                            cached
                          </i>
                        </button>
                      </Tippy>
                    </li>
                  </ul>
                </header>
                <div className="tbody" role="rowgroup">
                  {table.rowList.map((row) => {
                    const graphIndicatorList = row.indicatorList.filter(
                      (indicator) => Boolean(indicator) && indicator!.dataType !== 10
                    ) as IndicatorExtendedSmart<IndicatorSystem>[];
                    const hasPtl = rowHasPtl(row.indicatorList, ptlList);
                    const isPtlExpanded = activePtlRow === row.tableRowId;
                    return (
                      <Fragment key={row.tableRowId}>
                        <ul className="row" role="row">
                          <li className="cell normal-holder">
                            <span className="normal-holder__wrapper">
                              <span>{row.groupName}</span>
                              <span className="normal-holder__actions">
                                <PtlToggle
                                  enabled={hasPtl}
                                  id={row.tableRowId}
                                  expanded={isPtlExpanded}
                                  onToggle={onPtlRowToggle}
                                />
                              </span>
                            </span>
                          </li>
                          {row.indicatorList.map((indicator, index) => {
                            if (!indicator) {
                              return <li key={index} className="cell brag-holder" role="cell" />;
                            }
                            const isLoadingWeight = isSavingWeights[indicator.indicatorId] !== undefined ? true : false;

                            return (
                              <li className="cell brag-holder" role="cell" key={index}>
                                <Modal
                                  {...INDICATOR_MODAL_GENERAL_PROPS}
                                  trigger={
                                    <Tippy
                                      placement="bottom"
                                      content={
                                        <div className="ta-center">
                                          <span style={{ fontSize: "1.2rem" }}>{t("dashboards:tooltipUpdatedOn")}</span>
                                          <br />
                                          <span>
                                            {formatToLocalString(indicator.valueTimestamp, UPDATED_ON_DATE_TIME_FORMAT)}
                                          </span>
                                        </div>
                                      }>
                                      <span>
                                        <IndicatorTableBrag indicator={indicator} />
                                      </span>
                                    </Tippy>
                                  }>
                                  <IndicatorModal extraISHInfo={{ systemDashboardId, unitId }} indicator={indicator} />
                                </Modal>
                                <WeightInput
                                  showWeights={(rba.WEIGHT_VIEW || rba.WEIGHT_EDIT) && showWeights}
                                  indicator={indicator}
                                  isLoading={isLoadingWeight}
                                  canEdit={canEditWeights}
                                  onWeightChange={onWeightChange(table.tableId, row.tableRowId)}
                                />
                              </li>
                            );
                          })}
                          <li className="cell graph-holder" role="cell">
                            {graphIndicatorList.length > 0 && (
                              <Modal
                                {...INDICATOR_MODAL_GENERAL_PROPS}
                                trigger={<Icon className="pointer">bar_chart</Icon>}>
                                <IndicatorGraph
                                  extraISHInfo={{ systemDashboardId, unitId }}
                                  indicators={graphIndicatorList}
                                  comparisonKey={row.comparisonGraphKey}
                                  indicatorGroupId={row.indicatorGroupId}
                                />
                              </Modal>
                            )}
                          </li>
                          {/* {row.map((cell: any, index: number) => (
                      <li
                        key={index}
                        className={classes({
                          cell: true,
                          [cell.type + "-holder"]: !!cell.type,
                        })}>
                        {cell.type === "normal" && cell.value}
                        {cell.type === "graph" && <i className="material-icons pointer">bar_chart</i>}
                        {cell.type === "brag" && (
                          <span
                            className={classes({
                              "brag pointer": true,
                              ["brag-" + cell.color]: true,
                            })}>
                            {cell.value}
                          </span>
                        )}
                      </li>
                    ))} */}
                        </ul>
                        <PtlRow
                          id={row.tableRowId}
                          enabled={hasPtl}
                          expanded={isPtlExpanded}
                          ptlList={ptlList}
                          indicatorList={row.indicatorList}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </div>
              {/* <aside className="table-footer ta-right">
              <a href="#!">Show Weighting</a>
            </aside> */}
            </div>
          </div>
        );
      })}
    </>
  );
};
