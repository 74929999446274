import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { updateToggleFavourite } from "../../store/actions/manual-updates";
import {
  Modal,
  Icon,
  STATIC_BRAGS,
  Button,
  Input,
  Tippy,
  DateTimePicker,
  DatePicker,
  BragWithTag,
} from "@tscore/react-components";
import { ModalTrigger } from "./modal-trigger";
import { ManualUpdatesEmptyRow } from "./empty";
import classNames from "classnames";
import Moment from "react-moment";
import {
  momentTimeDateFormat,
  formatToDate,
  formatDateToUnixMs,
  selectedUnixUTCMsToLocalTimeZoneDate,
} from "../../lang/DateTimeFormats";
import { setHours, setMinutes, hoursMinutesNow, hoursMinutesMidnight, isToday } from "../../helpers/date-time";
import { generateBragAccents, isGray } from "../../helpers/brag-helpers";
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { INPUT_AUTOCOMPLETE_OFF_VALUE } from "../../globals/settings";
//? TYPES:
import { IndicatorManual } from "../../types/indicator";
// import { TFunction } from "i18next";

interface Props {
  isTouched: undefined | boolean;
  hasError: undefined | string;
  currentActiveRow: number;
  rowindex: number;
  row: IndicatorManual;
  newValuePlaceholder: string;
  id: number;
  onChangeIndicator: any; // todo
  setCurrentActiveRow: any; // todo
}

const CustomValueDatePicker = forwardRef(({ onClick, value, onChange, type, ...props }: any, ref: any) => (
  <div ref={ref} className="icon-input left date" onClick={onClick}>
    <input
      type="text"
      {...props}
      autoComplete={INPUT_AUTOCOMPLETE_OFF_VALUE}
      onChange={onChange}
      value={value}
      data-bragged={!!value}
      style={{ "--accent": value ? STATIC_BRAGS.BLUE["--accent"] : undefined }}
      className="input input-brag"
    />
    <i className="icon material-icons">calendar_today</i>
  </div>
));

export const IndicatorRow: React.FC<Props> = ({
  row,
  id,
  currentActiveRow,
  rowindex,
  onChangeIndicator,
  setCurrentActiveRow,
  newValuePlaceholder,
  isTouched,
  hasError,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDragDisabled = !row.isFavourite;
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: row.indicatorId,
    data: { indicator: row },
    disabled: {
      draggable: isDragDisabled,
      droppable: isDragDisabled,
    },
  });
  const updatedTransform = transform ? { ...transform, scaleY: 1 } : transform;
  const style = {
    transform: CSS.Transform.toString(updatedTransform),
    transition,
  };
  if ((row as any).isTemporary) {
    return <ManualUpdatesEmptyRow />;
  }
  return (
    <div
      ref={setNodeRef}
      // ref={isDragDisabled ? undefined : setNodeRef}
      style={style}
      className={classNames("row__holder", {
        active: !!row.newValue || currentActiveRow === row.indicatorId,
        "is-dragging": isDragging,
      })}
      // data-temporary={(row as any).isTemporary}
      key={row.indicatorId}>
      <ul
        onClick={() => setCurrentActiveRow(row.indicatorId)}
        className={classNames("row", {
          errored: !isTouched && hasError,
        })}>
        <li className="cell" data-id="drag">
          <div
            {...attributes}
            {...listeners}
            ref={setActivatorNodeRef}
            data-disabled={isDragDisabled}
            className="drag-handle">
            <div>
              <Icon className="color-gray">drag_indicator</Icon>
            </div>
          </div>
        </li>
        <li className="cell favourite-holder" data-id="favourite">
          <Tippy
            content={row.isFavourite ? t("generic:Remove from favourites") : t("generic:Add to favourites")}
            placement="bottom"
            delay={[800, 0]}>
            <div
              role="button"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                dispatch(updateToggleFavourite(id, row));
              }}>
              <Icon
                className={classNames({
                  "color-orange": row.isFavourite,
                  "color-lightgray": !row.isFavourite,
                })}>
                star
              </Icon>
            </div>
          </Tippy>
        </li>
        <li className="cell id-holder" data-id="id">
          <span>{row.indicatorId}</span>
        </li>
        <li className="cell normal-holder" data-id="name">
          <div>
            {row.indicatorName}
            {row.indicatorDescription && row.indicatorDescription !== "" && (
              <Tippy
                maxWidth={540}
                content={<div className="indicator-description-tooltip">{row.indicatorDescription}</div>}>
                <span style={{ marginLeft: "0.4rem" }}>
                  <Icon className="info-icon">info</Icon>
                </span>
              </Tippy>
            )}
          </div>
          <div className="meta">
            {row.updatedBy.fullname}
            {` `}
            <Tippy
              content={
                <div style={{ fontSize: "1.1rem" }}>
                  <span>{t("manualUpdates:Last updated")}: </span>
                  <Moment fromNow>{row.valueTimestamp}</Moment>
                </div>
              }
              placement="right">
              <span>
                <Moment format={momentTimeDateFormat}>{row.valueTimestamp}</Moment>
                <Icon className="info-icon">info</Icon>
              </span>
            </Tippy>
          </div>
          {!isTouched && <div className="form-error is-visible">{hasError}</div>}
        </li>
        <li className="cell brag-holder" data-id="brag">
          <BragWithTag
            className="longer"
            accents={generateBragAccents(row, false)}
            isGreyWithTag={isGray(row)}
            updatedAtText={<Moment fromNow>{row.valueTimestamp}</Moment>}>
            {row.readableValue || row.value}
          </BragWithTag>
          {row.dataType === 10 ? (
            <div data-type="date">
              <DatePicker
                popperPlacement="top-end"
                // tabIndex={(rowindex + 1) * rowindex + 1}
                selected={selectedUnixUTCMsToLocalTimeZoneDate(row.newValue as number)}
                onChange={(date: any) => {
                  const hack =
                    date.getHours() === 0 ? new Date(date.getTime() - date.getTimezoneOffset() * 60000) : date;
                  onChangeIndicator({ target: { value: formatDateToUnixMs(hack) } }, row, "newValue");
                }}
                customInput={<CustomValueDatePicker tabIndex={(rowindex + 1) * rowindex + 1} />}
                name="newValue"
                placeholderText={newValuePlaceholder}
              />
            </div>
          ) : (
            <Input
              data-type="number"
              tabIndex={(rowindex + 1) * rowindex + 1}
              onFocus={() => setCurrentActiveRow(row.indicatorId)}
              className="input-brag"
              placeholder={newValuePlaceholder}
              data-bragged={!!row.newValueColour?.["--accent"]}
              style={{ "--accent": row.newValueColour?.["--accent"] } as Style}
              value={row.newValue || ""}
              onChange={(event: any) => onChangeIndicator(event, row)}
              name="newValue"
              autoComplete="off"
            />
          )}
          <Tippy content={t("manualUpdates:tooltipInputHelp")} placement="bottom">
            <i className="material-icons new-value-help-icon">help</i>
          </Tippy>
          {row.thresholdList.length === 0 ? (
            <span className="trigger__spacer" />
          ) : (
            <Modal
              style={{ maxWidth: "800px" }}
              trigger={
                <Tippy content={t("manualUpdates:Trigger details")} placement="bottom">
                  <span style={{ display: "block" }} data-tooltiped>
                    <Button colour="lightgray-outline" length="shorter" icon="view_list"></Button>
                  </span>
                </Tippy>
              }>
              <ModalTrigger threshold={row.thresholdList} name={row.indicatorName} />
            </Modal>
          )}
        </li>
      </ul>
      <ul className="row expandable" onClick={() => setCurrentActiveRow(row.indicatorId)}>
        <li className="cell" data-id="drag" />
        <li className="cell" data-id="favourite" />
        <li className="cell" data-id="id" />
        <li className="cell" data-id="name">
          <Input
            tabIndex={(rowindex + 1) * rowindex + 2}
            maxLength={500}
            placeholder={t("manualUpdates:Enter comment")}
            onChange={(event: any) => onChangeIndicator(event, row)}
            name="comment"
            value={row.comment || ""}
            autoComplete="off"
          />
        </li>
        <li className="cell timestamp-holder" data-id="brag">
          <DateTimePicker
            selected={formatToDate(row.newValueTimestamp)}
            onChange={(date: any) => onChangeIndicator({ target: { value: date } }, row, "newValueTimestamp")}
            minTime={setHours(setMinutes(new Date(), 0), 0)}
            injectTimes={[hoursMinutesNow()]}
            // value={formatToStringByFormat(hoursMinutesNow(), undefined, momentTimeDateFormat)}
            maxTime={
              isToday(row.newValueTimestamp ? new Date(row.newValueTimestamp) : new Date())
                ? hoursMinutesNow()
                : hoursMinutesMidnight()
            }
            filterDate={(date: any) => {
              return new Date() > date;
            }}
            placeholderText={t("manualUpdates:Date")}
            tabIndex={(rowindex + 1) * rowindex + 3}
          />
        </li>
      </ul>
    </div>
  );
};
