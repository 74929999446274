import { IndicatorManual } from "../../../types/indicator";
import IndicatorThreshold from "../../../types/indicator-threshold";
import { UnsavedIndicators } from "../../types/manual-updates-state";
type Indicator = IndicatorManual;

export const resetIndicator = (indicator: Indicator): Indicator => {
  const { newValueTimestamp, newValue, comment, newValueColour, ...scrappedIndicator } = indicator;
  return scrappedIndicator;
};

export const saveIndicators = (indicators: Indicator[], unsaved: UnsavedIndicators, response: any[]): Indicator[] => {
  if (Object.keys(unsaved).length === 0) {
    return indicators;
  }
  Object.values(unsaved).forEach((indicator: Indicator) => {
    const id = indicator.indicatorId;
    const index: number = indicators.findIndex((item) => item.indicatorId === id);
    if (index === -1) {
      // ? if saved indicator not in current page, ignore updating UI on this one
      return;
    }
    const responseIndicator: any = response.find((item) => item.indicatorId === id);
    if (!responseIndicator) {
      return;
    }
    // ? NEW entered date < old date in system
    const oldDate: Date = new Date(responseIndicator.valueTimestamp);
    const newDate: Date = new Date(indicators[index].valueTimestamp as string);
    // newDate.setSeconds(59);
    const newTimestampFresh: boolean = oldDate.getTime() >= newDate.getTime() ? true : false;
    // console.log(oldDate, newDate);

    const { isFavourite, orderId } = indicators[index];
    const prevIndicator = { ...resetIndicator(indicator), isFavourite, orderId };
    // ? Get the threshold for new value from the list (as the response doesn't provide new colours)
    if (!newTimestampFresh) {
      indicators[index] = { ...prevIndicator };
      // eslint-disable-next-line
      console.log("(no ui change) Fresher date already present for " + id);
    } else {
      const threshold: IndicatorThreshold | any =
        prevIndicator.thresholdList.find(
          (threshold) => threshold.colorCodeBg === indicator.newValueColour?.["--accent"]
        ) || {};
      indicators[index] = {
        ...prevIndicator,
        value: responseIndicator.value,
        valueTimestamp: responseIndicator.valueTimestamp,
        readableValue: responseIndicator.readableValue,
        newValueColour: {},
        valueColorCodeFg: threshold.colorCodeFg || indicator.valueColorCodeFg,
        valueColorCodeBg: threshold.colorCodeBg || indicator.valueColorCodeBg,
        updatedBy: responseIndicator.updatedBy,
        greyOut: responseIndicator.greyOut,
      };
    }
  });
  return indicators;
};
