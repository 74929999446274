import React, { useEffect } from "react";
import Meta from "../../components/meta";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportList, fetchDeleteReport, fetchDownloadReportWithTokenCheck } from "../../store/actions/reports";
import classNames from "classnames";
import { navigateToPage } from "../../helpers/change-page";
import { InlineError, Button, Modal } from "@tscore/react-components";
import Pagination from "../../components/structure/pagination";
import { formatToISOFromFilter, formatToString } from "../../lang/DateTimeFormats";
import { NavLink } from "react-router-dom";
import { DATE_TIME_FORMAT, WINTER_REPORT_NAME, RBA_PARTIAL } from "./consts";
import { ModalRequest } from "./modal-request";
import { ModalAdditionalQuestions } from "./modal-additional-questions";
import { EmptyReportList } from "./empty-report-list";
import { ReportListFilters } from "./report-list-filters";
import { MODULE_URLS } from "../../globals/internal-urls";
import { useTranslation } from "react-i18next";
import { ModalDelete } from "./modal-delete";
import { useSearchParams } from "../../hooks/use-search-params";
import { SpecialNarrative } from "./special-narrative";
import { SortHeading } from "../../components/sort/sort-heading";
import { SortDropdown } from "../../components/sort/sort-dropdown";
import { ClearFilters } from "../../components/sort/clear-filters";
import fallbackify from "../../helpers/fallbackify";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";
import { SortItem } from "../../components/sort/sort-dropdown";
// import { TFunction } from "i18next";

interface Params {
  id: "all" | string;
  type: "winterReport" | string;
  pretty?: string;
}

type ReportListPageProps = RouteComponentProps<Params>;

const Sort = {
  _id: "_id",
  areaName: "areaName",
  submittedDatetime: "submittedDatetime",
  requestedDatetime: "requestedDatetime",
} as const;

const allowedSort = fallbackify(
  {
    "NHS Region": {
      [Sort._id]: Sort._id,
      [Sort.areaName]: Sort.areaName,
      [Sort.submittedDatetime]: Sort.submittedDatetime,
      [Sort.requestedDatetime]: Sort.requestedDatetime,
    },
  },
  { defaultValue: {} as Record<string, string> }
);

export const ReportListPage: React.FC<ReportListPageProps> = ({ match, history, location }) => {
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  // const searchParams = decodeParams(location.search);
  const id = match.params.id === "all" ? "all" : parseInt(match.params.id, 10);
  const type = isNaN(match.params.type as unknown as number) ? WINTER_REPORT_NAME : match.params.type;
  const rawType = match.params.type;
  // const reportTypeName = searchParams.reportName || "";
  const dispatch = useDispatch();
  useEffect(() => {
    const page = parseInt(searchParams.page as string, 10) || 1;
    const filters = {
      fromDate: formatToISOFromFilter(searchParams.from),
      toDate: formatToISOFromFilter(searchParams.to, "end"),
      areaIdList: searchParams.areaIds ? searchParams.areaIds.split(",") : undefined,
    };
    async function fetchInitial() {
      await dispatch(fetchReportList(id, type, page, filters, searchParams.parsedSort));
    }
    // if (type === WINTER_REPORT_NAME || (type !== WINTER_REPORT_NAME && reportTypeName !== "")) {
    // extra check
    fetchInitial();
    // }
  }, [dispatch, id, type, searchParams]);
  const state = useSelector((state: ApplicationState) => state.reportsReducer[id] || state.reportsReducer[-1]);
  const { reports, meta, downloadProgress } = state;
  const isFetching = state.isFetching["GET_REPORT_LIST"];
  const rbaAll = useSelector((state: ApplicationState) => state.menuReducer.rba["NHS Region"]);
  const rbaInput = rbaAll[id] || rbaAll[-1];
  const rba = {
    REPORT_SUBMITTER: rbaInput[RBA_PARTIAL(type, "SUBMITTER")],
    REPORT_REQUESTER: rbaInput[RBA_PARTIAL(type, "REQUESTER")],
    REPORT_FILLER: rbaInput[RBA_PARTIAL(type, "FILLER")],
    REPORT_DELETE: rbaInput[RBA_PARTIAL(type, "DELETE")],
  };
  const module = useSelector((state: ApplicationState) => state.menuReducer.meta.module);
  // const showPrettyStatus = (status: string | null) => {
  //   if (module === "Resilience") {
  //     if (status === "Requested") {
  //       return "Pending submission";
  //     }
  //     if (status === "Completed") {
  //       return "Report submitted";
  //     }
  //     return status;
  //   }
  //   if (status === "Completed") {
  //     return "Received";
  //   }
  //   return status;
  // };
  const changePage = (page: number) => navigateToPage(history, location.search, page);
  const pageChanging = (_page: number): boolean => {
    return true;
  };
  const headings: (SortItem & { class?: string; style?: any })[] = [
    {
      value: t("regionReports:mainTable.0"),
      class: "w1 nowrap",
      sortName: allowedSort[module]["_id"],
      sortType: "number",
    },
    {
      value: t("regionReports:mainTable.1"),
      class: classNames({ hideme: module === "Resilience", w1: type === WINTER_REPORT_NAME }),
      style: { minWidth: type === WINTER_REPORT_NAME ? "130px" : "auto" },
      sortName: allowedSort[module]["areaName"],
    }, // organisation
    // { value: t("regionReports:mainTable.2"), class: "nowrap" },
    // { value: t("regionReports:mainTable.3"), class: "nowrap" },
    { value: t("regionReports:mainTable.2"), class: "" },
    { value: t("regionReports:mainTable.3"), class: "" },
    { value: t("regionReports:mainTable.4"), class: classNames({ hideme: type !== WINTER_REPORT_NAME }) },
    { value: t("regionReports:mainTable.5"), class: classNames({ hideme: type !== WINTER_REPORT_NAME }) },
    { value: t("regionReports:mainTable.6"), class: "nowrap" },
    { value: t("regionReports:mainTable.7"), class: "", style: { minWidth: "120px" } },
  ];
  const sortList = headings;
  // const sortList = [
  //   ...headings,
  //   { value: "Submitted Date", sortName: allowedSort[module]["submittedDatetime"], sortType: "date" },
  //   { value: "Requested Date", sortName: allowedSort[module]["requestedDatetime"], sortType: "date" },
  // ];
  const { organisationList, reportTypeNames } = useSelector((state: ApplicationState) => state.reportsReducer["all"]);
  const reportTypeName = reportTypeNames[type] || "";
  const reportName = type === WINTER_REPORT_NAME ? t("regionReports:Regional Report") : reportTypeName;
  const defaultOrganisation = { value: id, label: "" };
  const areaName: string = (
    (organisationList[type] || []).find((item: any) => item.value === id) || defaultOrganisation
  ).label;
  const organisationSearchString = (organisationName = areaName) => {
    return (
      "?organisation=" +
      encodeURIComponent(organisationName as string) +
      "&source=" +
      encodeURIComponent(location.pathname + location.search)
    );
  };
  const downloadReport = (to: string, reportId: number | string) => {
    dispatch(fetchDownloadReportWithTokenCheck(id, type, reportId, to));
  };
  return (
    <main>
      <Meta
        title={id === "all" ? reportName : areaName}
        breadcrumbs={t("regionReports:breadcrumbs", {
          returnObjects: true,
          areaOrReportName: id === "all" ? areaName : reportName,
        })}
      />
      <header className="content-header">
        <div style={{ width: "100%" }}>
          {/* {JSON.stringify(rba)}
          {JSON.stringify(rbaInput)} */}
          {module === "Resilience" && rba.REPORT_SUBMITTER && type === "winterReport" && (
            <SpecialNarrative
              id={id}
              to={`/report/${rawType}/$REPORT_ID/submit${organisationSearchString()}&action=complete-draft-report`}
            />
          )}
          {module === "Resilience" && rba.REPORT_SUBMITTER && (
            <NavLink className="btn green" to={`/report/${rawType}/${id}/new${organisationSearchString()}`}>
              {t("regionReports:New Report", {
                reportName: type === WINTER_REPORT_NAME ? t("regionReports:Regional Report") : reportTypeName,
              })}
            </NavLink>
          )}
          {module === "NHS Region" && (
            <Modal
              style={{ maxWidth: "520px", overflow: "visible" }}
              trigger={
                <Button colour="blue">
                  {t("regionReports:Request Report", {
                    reportName: reportTypeName,
                    context: type === WINTER_REPORT_NAME ? "WINTER" : undefined,
                  })}
                </Button>
              }>
              <ModalRequest reportType={type} />
            </Modal>
          )}
        </div>
      </header>
      <div id="report-list">
        <header className="data-toolbar mb12">
          <div>
            {id === "all" && (
              <>
                <ClearFilters filters={["sort", "areaIds", "to", "from"]} />
                <ReportListFilters reportType={type} searchParams={searchParams} />
                <span className="data-toolbar__divider" />
                <SortDropdown searchParams={searchParams} list={sortList} />
              </>
            )}
          </div>
        </header>
        {!isFetching && reports.length === 0 ? (
          <InlineError
            style={{ height: "30vh" }}
            className="middle"
            title={t("regionReports:noReportsTitle")}
            description={t("regionReports:noReportsDescription")}
            icon="grid_off"
          />
        ) : (
          <>
            <div
              className="table bordered no-vertical-lines main shadow-hover-effect shadowed"
              style={{ position: "relative" }}>
              <header className="thead">
                <ul className="row">
                  {headings.map((cell) => (
                    <SortHeading key={cell.value} cell={cell} parsedSort={searchParams.parsedSort} />
                  ))}
                </ul>
              </header>
              <div className="tbody">
                {isFetching ? (
                  <EmptyReportList reportType={type} module={module} length={reports.length} />
                ) : (
                  reports.map((report) => {
                    const status = report.status;
                    return (
                      <ul className="row" key={report.id}>
                        <li className="cell">{report.id === -1 ? "NEW" : report.id}</li>
                        <li className={classNames("cell", { hideme: module === "Resilience" })}>{report.areaName}</li>
                        <li className="cell nowrap">
                          {report.requestedDatetime && (
                            <div className="cell-meta__holder">
                              <div className="cell-meta__icon__holder">
                                <i className="material-icons color-gray">shortcut</i>
                              </div>
                              <div>
                                <div>{report.idUserRequestedBy?.fullname}</div>
                                <div className="cell-date">
                                  {t("regionReports:onDate", {
                                    datetime: formatToString(report.requestedDatetime, DATE_TIME_FORMAT),
                                  })}
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                        <li className="cell nowrap">
                          {report.submittedDatetime && (
                            <div className="cell-meta__holder">
                              <div className="cell-meta__icon__holder">
                                <i className="material-icons color-green">task_alt</i>
                              </div>
                              <div>
                                <div>{report.idUserSubmittedBy?.fullname}</div>
                                <div className="cell-date">
                                  {t("regionReports:onDate", {
                                    datetime: formatToString(report.submittedDatetime, DATE_TIME_FORMAT),
                                  })}
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                        <li className={classNames("cell nowrap", { hideme: type !== WINTER_REPORT_NAME })}>
                          {report.moreInfoRequestedDatetime && (
                            <div className="cell-meta__holder">
                              <div className="cell-meta__icon__holder">
                                <i className="material-icons color-gray">shortcut</i>
                              </div>
                              <div>
                                <div>{report.idUserMoreInfoRequestedBy?.fullname}</div>
                                <div className="cell-date">
                                  {t("regionReports:onDate", {
                                    datetime: formatToString(report.moreInfoRequestedDatetime, DATE_TIME_FORMAT),
                                  })}
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                        <li className={classNames("cell nowrap", { hideme: type !== WINTER_REPORT_NAME })}>
                          {report.moreInfoSubmittedDatetime && (
                            <div className="cell-meta__holder">
                              <div className="cell-meta__icon__holder">
                                <i className="material-icons color-green">task_alt</i>
                              </div>
                              <div>
                                <div>{report.idUserMoreInfoSubmittedBy?.fullname}</div>
                                <div className="cell-date">
                                  {t("regionReports:onDate", {
                                    datetime: formatToString(report.moreInfoSubmittedDatetime, DATE_TIME_FORMAT),
                                  })}
                                </div>
                              </div>
                            </div>
                          )}
                        </li>
                        <li className="cell">
                          {t(`regionReports:status.${status}`, { defaultValue: status, context: module })}
                        </li>
                        <li className="cell more-holder nowrap">
                          {report.isOldReport && <i className="material-icons">info</i>}
                          {module === "Resilience" && (
                            <>
                              {status === "Requested" && rba.REPORT_SUBMITTER && (
                                <NavLink
                                  className="btn primary"
                                  to={`/report/${rawType}/${report.id}/submit${organisationSearchString()}`}>
                                  {t("regionReports:Submit report")}
                                </NavLink>
                              )}
                              {type === WINTER_REPORT_NAME &&
                                status === "More info requested" &&
                                rba.REPORT_SUBMITTER && (
                                  <NavLink
                                    className="btn primary"
                                    to={`/report/${rawType}/${report.id}/submit-info${organisationSearchString()}`}>
                                    {t("regionReports:Submit more info")}
                                  </NavLink>
                                )}
                              {status === "Draft" && rba.REPORT_SUBMITTER && (
                                <NavLink
                                  className="btn primary"
                                  to={`/report/${rawType}/${
                                    report.id
                                  }/submit${organisationSearchString()}&action=complete-draft-report`}>
                                  {t("regionReports:Complete report")}
                                </NavLink>
                              )}
                            </>
                          )}
                          {(status === "More info requested" || status === "Completed") && (
                            <>
                              <NavLink
                                className="btn primary"
                                to={`${MODULE_URLS[module]}report/${rawType}/${
                                  report.id
                                }/view${organisationSearchString(report.areaName)}`}>
                                {t("regionReports:View")}
                              </NavLink>
                              <Button
                                isLoading={
                                  downloadProgress[report.id] &&
                                  downloadProgress[report.id] < 100 &&
                                  downloadProgress[report.id] > 0
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  downloadReport(
                                    `/report/${rawType}/${
                                      report.id
                                    }/view${organisationSearchString()}&downloadId=${id}`,
                                    report.id
                                  )
                                }>
                                {t("regionReports:Download")}
                              </Button>
                            </>
                          )}
                          {module === "NHS Region" && type === WINTER_REPORT_NAME && status === "Completed" && (
                            <Modal
                              style={{ maxWidth: "520px" }}
                              trigger={
                                <Button
                                  className="btn primary"
                                  style={{
                                    visibility: (rbaAll[report.areaId] || {}).WINTER_REPORT_REQUESTER
                                      ? "visible"
                                      : "hidden",
                                  }}>
                                  {t("regionReports:Request More Info")}
                                </Button>
                              }>
                              <ModalAdditionalQuestions id={id} reportId={report.id as number} />
                            </Modal>
                          )}
                          {((["Requested", "Draft"].includes(status!) && rba.REPORT_SUBMITTER) ||
                            rba.REPORT_DELETE) && (
                            <Modal
                              trigger={<Button length="shorter" colour="red" icon="delete"></Button>}
                              className="dialog">
                              <ModalDelete
                                id={report.id}
                                areaName={report.areaName}
                                onDelete={() => dispatch(fetchDeleteReport(id, type, report.id as number))}
                              />
                            </Modal>
                            // <Button
                            //   colour="red"
                            //   icon="delete"
                            //   onClick={() => dispatch(fetchDeleteReport(id, type, report.id as number))}></Button>
                          )}
                          <Button style={{ padding: 0, visibility: "hidden" }} aria-hidden="true"></Button>
                        </li>
                      </ul>
                    );
                  })
                )}
              </div>
            </div>
            <Pagination
              label={t("regionReports:mainPaginationLabel")}
              settings={meta}
              onChangePage={(page: number) => changePage(page)}
              onChangingPage={(page: number) => pageChanging(page)}
            />
          </>
        )}
        {/* {JSON.stringify({
          id,
          type,
          rba,
          module,
        })} */}
      </div>
    </main>
  );
};
