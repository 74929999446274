import React, { Component } from "react";
import Meta from "../../components/meta";
import { useSelector } from "react-redux";
import { COMPLETED_STATUS, WINTER_REPORT_NAME } from "./consts";
import { Button, Checkbox, Dropdown, Icon, Toast, Modal, Tippy, InlineError } from "@tscore/react-components";
import ReportEmpty from "./report-empty";
import { FormField } from "../../components/forms";
import { Formik } from "formik";
import classNames from "classnames";
import { arrayToObject } from "../../helpers/array-to-object";
import { getValueType, getIndicator } from "../../helpers/report-helpers";
import { findMatchingThreshold } from "../../helpers/brag-helpers";
import { calculateNewIndicatorColourByThreshold, PRESSURE_LEVEL_BRAG_MAPPING } from "../../helpers/brag-helpers-legacy";
import isEqual from "lodash/isEqual";
import throttle from "lodash/throttle";
import { decodeParams } from "../../helpers/params";
import IndicatorService from "./services/temp-indicator.service";
import ReportService from "./services/report.service";
// import { FORM_ERRORS } from "../../lang/error";
import axios from "axios";
import { ModalSaveSuccess, modalSaveSuccessSettings } from "./modal-success";
import { ModalPrefill } from "./modal-prefill";
import { AdditionalQuestionListForm } from "./additional-question-list-form";
import { ReportErrorSummary } from "./report-error-summary";
import { useTranslation, withTranslation } from "react-i18next";
import i18n from "i18next";
import { RBA_PARTIAL } from "./consts";
// ? VALIDATOR
import { getElementVisibility, validateData } from "@forms/validation";
// ? TYPES:
import { ApplicationState } from "../../store/reducers";
import { RouteComponentProps } from "react-router-dom";
import { AxiosError, AxiosPromise } from "axios";
import { ImportedConfigIndicator, ConfigIndicator, ConfigIndicatorExtra } from "../../types/forms-config";
import { TemplateDto, ElementDto } from "@forms/validation/model";
import {
  RenderType,
  ReportType,
  ReportStatus,
  AdditionalQuestion,
  FormStateList,
  FormValues,
  FormValue,
  CurrentAndPastIndicatorStatus,
} from "../../types/forms/general";
import { RecentReport } from "../../types/forms/recent-report";
// import { NavLink } from "react-router-dom";
// import { HACK_currentToPastIndicatorsTemplate } from "./hack-current-to-past-indicators";
import { TFunction } from "i18next";
// import IndicatorNew from "../../types/indicator-new";
import { IndicatorRegionReport } from "../../types/indicator";

interface SegregatedIndicators {
  current: ImportedConfigIndicator[];
  past: ImportedConfigIndicator[];
}

interface MatchParams {
  id: string; // organisation id for new, report id for view
  reportType: ReportType;
  renderType: RenderType;
  friendly?: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  t: TFunction;
  onSuccessSubmit?: () => void;
}
interface State {
  organisationId: string | number | null;
  reportId: string | number | null;
  reportType: string;
  isSavingDraft: boolean;
  isFetching: boolean;
  isFetchingIndicators: boolean;
  template: TemplateDto;
  form: FormValues;
  indicators: CurrentAndPastIndicatorStatus;
  hasErrored: null | string;
  visibility: FormStateList;
  reportStatus: ReportStatus;
  additionalQuestionList: null | AdditionalQuestion[];
  recentList: RecentReport[];
  isFormDirty: boolean;
  reportSettings: {
    showReadableValues: boolean;
    showReadableValuesDisabled: boolean;
  };
  isPreviousAnswersEnabled: boolean;
}

type ErrorPlot = {
  [key: string]: string[];
};

const SETTINGS: {
  postProcessIndicatorValue: PostProcessOptions;
} = {
  postProcessIndicatorValue: "alwaysApplyFreshMetaToIndicators",
};

const COSMETIC_ELEMENT_NAME_LIST = ["section", "label", "gridLayout", "systemDashboardElement"];

const ERROR_T_STR = "formErrors.";
const GET_ERROR_GLOBAL = (name: string, options?: Record<string, unknown>) =>
  i18n.t("regionReports:" + ERROR_T_STR + name, options);

const ERROR_BODY = () => ({ title: GET_ERROR_GLOBAL("defaultTitle"), body: GET_ERROR_GLOBAL("defaultBody") });

const validateIndicatorsByThresholds = (
  values: FormValues,
  template: TemplateDto,
  indicators: CurrentAndPastIndicatorStatus
): ErrorPlot => {
  const listOfQuestionWithIndicators = getListOfQuestionsWithIndicators(template.elements);
  return Object.entries(listOfQuestionWithIndicators).reduce((acc, [questionId, element]) => {
    const configIndicators = values[questionId] as ConfigIndicator[];
    const { errorTitles, editableCount } = configIndicators.reduce(
      (acc2, configIndicator, i) => {
        const importedIndicator = element.config.indicators[i] as ImportedConfigIndicator;
        if (importedIndicator.isEditable) {
          acc2.editableCount += 1;
        }
        if (!importedIndicator.isEditable || (!configIndicator.value && configIndicator.value !== 0)) {
          return acc2;
        }
        const indicator = getIndicator(importedIndicator, indicators);
        if (!indicator || !indicator.thresholdList || indicator.thresholdList.length === 0) {
          return acc2;
        }
        const found = findMatchingThreshold(indicator, parseFloat(configIndicator.value as string));
        if (found) {
          return acc2;
        }
        acc2.errorTitles.push(importedIndicator.title);
        return acc2;
      },
      { errorTitles: [] as string[], editableCount: 0 }
    );
    if (errorTitles.length > 0) {
      acc[questionId] = errorTitles.map((title) =>
        GET_ERROR_GLOBAL("validationThreshold", { count: editableCount, replace: { label: title } })
      );
      // acc[questionId] = errorTitles.map((title) => (editableCount === 1 ? errorText : `${errorText} (${title})`));
    }
    return acc;
  }, {} as ErrorPlot);
};
const validate = (values: FormValues, template: TemplateDto): FormStateList => {
  return validateData(values, template) as FormStateList;
};

const flattenPastIndicators = (obj: any, a: IndicatorRegionReport[] = [], depth = 1) => {
  Object.values(obj).forEach((v: any) => {
    if (depth === 4) {
      a.push(v as CurrentAndPastIndicatorStatus["past"][0][0][0][0]);
    } else {
      flattenPastIndicators(v, a, depth + 1);
    }
  });
  return a;
};

const isReadableValuesToggleDisabled = (
  current: { data: { results: IndicatorRegionReport[] } },
  past: { data: any }
) => {
  const all = [...current.data.results, ...flattenPastIndicators(past.data)];
  return all[0] && typeof all[0].readableValue === "undefined" ? true : false;
  // return all.some((indicator) => typeof indicator.readableValue === "string");
};

const validateAdditional = (values: any): FormStateList => {
  let errors = {};
  errors = values.additionalQuestionList.reduce((final: any, item: AdditionalQuestion, index: number) => {
    return {
      ...final,
      ...((item.answer as string).length === 0 && { [index]: [GET_ERROR_GLOBAL("additionalQuestionRequired")] }),
    };
  }, {});
  return errors;
};

const getIndicatorInitialValue = (
  item: ImportedConfigIndicator,
  indicators: CurrentAndPastIndicatorStatus
): ConfigIndicatorExtra => {
  const indicator =
    getIndicator(item, indicators) ||
    ({
      displayOption: 3,
      value: "",
      dataLabel: undefined,
      valueType: undefined,
    } as unknown as IndicatorRegionReport);
  const value = !item.isEditable ? indicator.value : item.defaultValue ?? "";
  const color = indicator && calculateNewIndicatorColourByThreshold(indicator, value);
  return {
    value,
    pressureLevel: !color || color === "blue" ? null : PRESSURE_LEVEL_BRAG_MAPPING[color as string],
    displayOption: indicator.displayOption,
    dataLabel: indicator.dataLabel,
    valueType: indicator.valueType,
    // dataType: dataType,
  };
};

const getInitialValue = (
  valueType: string,
  item: any,
  indicators: CurrentAndPastIndicatorStatus = {
    current: {},
    past: {},
  }
): any => {
  if (valueType === "ARRAY") {
    return [];
  }
  if (valueType === "DATETIME_RANGE" || valueType === "DATE_RANGE" || valueType === "TIME_RANGE") {
    return {
      from: "",
      to: "",
    };
  }
  if (valueType === "INDICATOR") {
    return item.config.indicators.map((item: ImportedConfigIndicator): ConfigIndicator => {
      const valueAndPlAndDt = getIndicatorInitialValue(item, indicators);
      return {
        // ? if not editable get the current value from Indicators
        ...valueAndPlAndDt,
        indicatorId: +item.indicatorId,
      };
    });
  }
  if (valueType === "DURATION") {
    return {
      h: "",
      m: "",
    };
  }
  if (valueType === "RICHTEXT") {
    return {
      state: undefined,
      plaintext: "",
    };
  }
  return "";
};

const getPrefillValue = (
  valueType: string,
  item: ElementDto | any,
  indicators: CurrentAndPastIndicatorStatus = {
    current: {},
    past: {},
  },
  appliedValue: FormValue
): any => {
  if (typeof appliedValue === "undefined") {
    //? if prefill doesn't have this question:
    // eslint-disable-next-line
    console.log("New question added to template.");
    return getInitialValue(valueType, item, indicators);
  }
  if (valueType === "INDICATOR") {
    return item.config.indicators.map((item: ImportedConfigIndicator, index: number): ConfigIndicator => {
      const appliedIndicator: ConfigIndicator | undefined = (appliedValue as ConfigIndicator[])[index];
      if (item.isEditable === true && appliedIndicator && +appliedIndicator.indicatorId === +item.indicatorId) {
        return {
          indicatorId: +item.indicatorId,
          value: appliedIndicator.value,
          pressureLevel: appliedIndicator.pressureLevel,
          displayOption: appliedIndicator.displayOption,
          dataLabel: appliedIndicator.dataLabel,
          valueType: appliedIndicator.valueType,
          ...(item.isCommentEnabled && appliedIndicator.comment && { comment: appliedIndicator.comment }),
          // dataType: appliedIndicator.dataType,
        };
      }
      // for non editable, or non-existing will get the indicator value or apply empty one
      const valueAndPlAndDt = getIndicatorInitialValue(item, indicators);
      return {
        // ? if not editable get the current value from Indicators
        ...valueAndPlAndDt,
        indicatorId: +item.indicatorId,
      };
    });
  }
  return appliedValue;
};

const generateForm = (
  elements: ElementDto[],
  indicators: CurrentAndPastIndicatorStatus = {
    current: {},
    past: {},
  },
  useOtherValues: FormValues | null = null
): any => {
  return elements.reduce((final: any, item: ElementDto) => {
    const children =
      item.children && item.children.length > 0 ? generateForm(item.children, indicators, useOtherValues) : {};
    const gridLayout = //eslint-disable-next-line
      item.fieldType === "gridLayout" ? getGridLayoutValues(item, generateForm, indicators, useOtherValues) : {};

    // ? CAN DO extra check for duplicate IDs - probably not needed
    return {
      ...final,
      ...(!COSMETIC_ELEMENT_NAME_LIST.includes(item.fieldType) && {
        [item.elementId]: !useOtherValues
          ? getInitialValue(getValueType(item), item, indicators)
          : getPrefillValue(getValueType(item), item, indicators, useOtherValues[item.elementId]),
      }),
      ...children,
      ...gridLayout,
    };
  }, {});
};

// ? === Post-processing values
const getListOfQuestionsWithIndicators = (elements: ElementDto[]): { [indicatorIdKey: string]: ElementDto } => {
  return elements.reduce((final: any, item: ElementDto) => {
    const children = item.children && item.children.length > 0 ? getListOfQuestionsWithIndicators(item.children) : {};
    const gridLayout = //eslint-disable-next-line
      item.fieldType === "gridLayout" ? getGridLayoutValues(item, getListOfQuestionsWithIndicators) : {};
    return {
      ...final,
      ...(getValueType(item) === "INDICATOR" && { [item.elementId]: item }),
      ...children,
      ...gridLayout,
    };
  }, {});
};

// const isValueFilledIn = (value: any): boolean => {
//   if (value !== "" && value !== undefined && value !== null) {
//     return true;
//   }
//   return false;
// };

const generateIndicatorValue = (
  item: Required<Pick<ConfigIndicator, "indicatorId">>,
  indicator: IndicatorRegionReport & { comment?: string }
) => {
  const color = calculateNewIndicatorColourByThreshold(indicator, indicator.value);
  return {
    indicatorId: item.indicatorId,
    value: indicator.value,
    pressureLevel:
      !color || color === "blue" || color === "white" ? null : PRESSURE_LEVEL_BRAG_MAPPING[color as string],
    displayOption: indicator.displayOption,
    valueType: indicator.valueType,
    dataLabel: indicator.dataLabel,
    ...(indicator.comment && { comment: indicator.comment }),
    // dataType: indicator.dataType ? indicator.dataType : 0,
  };
};

// const applyOriginalIndicatorValuesToEmptyIndicator = (
//   value: ConfigIndicator[],
//   configIndicators: ImportedConfigIndicator[],
//   indicators: CurrentAndPastIndicatorStatus
// ): FormValue => {
//   return value.map((item: ConfigIndicator, index: number): ConfigIndicator => {
//     if (item.value !== "" || !configIndicators[index] || configIndicators[index].isEditable === false) {
//       // ? if filled in, return normal (or if can't find the configIndicator - shouldn't happen, or if isEditable is false - also shouldn't happen)
//       return item;
//     }
//     const indicator = getIndicator(configIndicators[index], indicators);
//     if (!indicator) {
//       // ? if cant find indicator (shouldn't happen)
//       return item;
//     }
//     return generateIndicatorValue(item, indicator);
//   });
// };

const alwaysApplyFreshMetaToIndicators = (
  value: ConfigIndicator[],
  configIndicators: ImportedConfigIndicator[],
  indicators: CurrentAndPastIndicatorStatus
): FormValue => {
  return value.map((item: ConfigIndicator, index: number): ConfigIndicator => {
    const config = configIndicators[index]!;
    const indicator = getIndicator(configIndicators[index], indicators);
    if (!indicator) {
      console.warn("_cant find indicator"); // ? (shouldn't happen)
      return item;
    }
    const valueBasedOnEditable = config.isEditable ? item.value ?? "" : indicator.value;
    if (Object.keys(item).length === 1) {
      // ? Mainly for "drafts", that only have indicatorId stored previously, so always get new data
      return generateIndicatorValue(item, {
        ...indicator,
        value: valueBasedOnEditable as number,
        comment: item.comment as string,
      });
    }
    if (config.isEditable === false) {
      // ? can't edit, always set fresh indicator value
      return generateIndicatorValue(item, indicator);
    }
    // if (isValueFilledIn(item.value)) {
    // ? if filled in, get fresh meta, but apply the value
    return generateIndicatorValue(item, {
      ...indicator,
      value: valueBasedOnEditable as number,
      comment: item.comment as string,
    });
    // return item;
    // }
    // return generateIndicatorValue(item, indicator);
  });
};

const postProcessOptions = {
  // applyOriginalIndicatorValuesToEmptyIndicator,
  alwaysApplyFreshMetaToIndicators,
  none: (value: ConfigIndicator[], _: any, _2: any) => {
    return value;
  },
};

type PostProcessOptions = keyof typeof postProcessOptions;

const postProcessAllIndicators = (
  elements: ElementDto[],
  indicators: CurrentAndPastIndicatorStatus,
  values: FormValues
): FormValues => {
  const listOfQuestionWithIndicators = getListOfQuestionsWithIndicators(elements);
  const output = Object.keys(values).reduce((final: any, key: string) => {
    const item = listOfQuestionWithIndicators[key]
      ? postProcessOptions[SETTINGS.postProcessIndicatorValue](
          values[key] as ConfigIndicator[],
          listOfQuestionWithIndicators[key].config.indicators as ImportedConfigIndicator[],
          indicators
        )
      : values[key];
    return {
      ...final,
      [key]: item,
    };
  }, {});
  return output;
};

// ? ===

function getGridLayoutValues(
  element: ElementDto,
  generateFormCallback: Function,
  indicators: CurrentAndPastIndicatorStatus = {
    current: {},
    past: {},
  },
  useOtherValues: FormValues | null = null
) {
  // ? maybe refactor
  return element.rowElements.reduce((finalrow: any, row: any) => {
    return {
      ...finalrow,
      ...row.colElements.reduce((finalcell: any, cell: any) => {
        return {
          ...finalcell,
          ...generateFormCallback(cell.value, indicators, useOtherValues),
        };
      }, {}),
    };
  }, {});
}

const OptionsList = ({
  elements,
  hasParent,
  ...props
}: {
  elements: ElementDto[];
  errors: ErrorPlot;
  indicators: CurrentAndPastIndicatorStatus;
  hasParent?: boolean;
  [key: string]: any;
}): JSX.Element | null => {
  return (
    <div className={classNames(hasParent ? "form-children" : "form-main")}>
      {elements.map((element): JSX.Element => {
        const isRequired = element.validationLogic && element.validationLogic.isRequired === true ? true : false;
        const hasChildren: boolean = element.children && element.children.length > 0;
        const isVisible = props.visibility[element.elementId] === false ? false : true;
        return (
          <div
            className={classNames("form-holder", { "is-hidden": !isVisible })}
            key={element.elementId}
            data-ft={element.fieldType}
            data-vdt={element.valueDataType}>
            <FormField
              element={element}
              errors={props.errors}
              showReadableValues={props.showReadableValues}
              // error={error}
              touched={props.touched}
              submitCount={props.submitCount}
              isFetchingIndicators={props.isFetchingIndicators}
              indicators={props.indicators}
              setFieldValue={props.setFieldValue}
              isRequired={isRequired}
              isVisible={props.visibility[element.elementId]}
              renderType={props.renderType}
            />
            {hasChildren && <OptionsList elements={element.children} {...props} hasParent={true} />}
          </div>
        );
      })}
    </div>
  );
};

const MainForm = (props: {
  indicators: CurrentAndPastIndicatorStatus;
  errors: ErrorPlot;
  elements: ElementDto[];
  [key: string]: any;
}): JSX.Element => {
  const { t } = useTranslation();
  const rbaAll = useSelector((state: ApplicationState) => state.menuReducer.rba["NHS Region"]);
  // const isFetching = false;
  const {
    isFetching,
    isFetchingIndicators,
    renderType,
    // indicators,
    // onKeyDown,
    // receivedErrors,
    // touched,
    // errors,
    dirty,
    // changeStateToDirty,
    // isValid,
    isSubmitting,
    handleSubmit,
    // submitCount,
    elements,
    // visibility,
    // status,
    // setStatus
    onSaveAsDraft,
    isSavingDraft,
    values,
    id,
    type,
  } = props;
  const rbaInput = rbaAll[id] || rbaAll[-1];
  // !will be needed
  // eslint-disable-next-line
  const rba = {
    REPORT_SUBMITTER: rbaInput[RBA_PARTIAL(type, "SUBMITTER")],
    REPORT_REQUESTER: rbaInput[RBA_PARTIAL(type, "REQUESTER")],
    REPORT_FILLER: rbaInput[RBA_PARTIAL(type, "FILLER")],
  };
  // console.log({ id, rba, rbaInput, type });
  // const onKeyDown = ({ keyCode }: any): boolean | void => keyCode === 13 && handleSubmit;
  const canSaveAsDraft: boolean = dirty;
  // !isValid && FORM_ERRORS.ttFormInvalid,
  const tooltipNoNewChanges: string = t("regionReports:" + ERROR_T_STR + "ttFormNoNewChanges");
  const tooltipTitle = [tooltipNoNewChanges];
  // const tooltipTitle = [!dirty ?? tooltipNoNewChanges].filter((message: string | undefined) => message);
  return (
    <form className="test-form" autoComplete="off" onSubmit={handleSubmit}>
      <OptionsList options={elements} {...props} />
      {renderType !== "view" && (
        <footer className="mt24">
          <ReportErrorSummary submitCount={props.submitCount} errors={props.errors} elements={elements} />
          <div className="ta-right">
            {["submit", "new"].includes(renderType) && (
              <Tippy
                disabled={canSaveAsDraft}
                content={
                  <ul className="list squared ta-left m0">
                    {tooltipTitle.map((message: string) => (
                      <li key={message}>{message}</li>
                    ))}
                  </ul>
                }>
                <span data-tooltipped>
                  <Button
                    isLoading={isSavingDraft}
                    disabled={!canSaveAsDraft}
                    colour="blue-outline"
                    onClick={() => onSaveAsDraft(values)}>
                    {t("regionReports:Save as Draft")}
                  </Button>
                </span>
              </Tippy>
            )}
            <Button
              style={{ marginLeft: "0.4rem" }}
              type="submit"
              length="longer"
              isLoading={isSubmitting}
              disabled={isFetching || isFetchingIndicators}>
              {t("regionReports:Submit")}
            </Button>
          </div>
        </footer>
      )}
    </form>
  );
};

// const AdditionalQuestionListForm = (props: any): any => {
//   const {
//     isFetching,
//     renderType,
//     // indicators,
//     // onKeyDown,
//     // receivedErrors,
//     // touched,
//     errors,
//     // dirty,
//     isSubmitting,
//     handleSubmit,
//     setFieldValue,
//     // submitCount,
//     reportStatus,
//     values,
//   } = props;
//   const handleChange = (value: any, index: number, newValue: string) => {
//     const updatedValue = { ...value, answer: newValue };
//     setFieldValue(`additionalQuestionList.${index}`, updatedValue);
//   };
//   return (
//     <form className="test-form" autoComplete="off" onSubmit={handleSubmit}>
//       <div className="form-main">
//         <div className="form-holder" data-ft="section">
//           <div className="element element-section">
//             <header>
//               <h2>Additional Questions</h2>
//               <p>Please fill in the answers to additional followup questions.</p>
//             </header>
//             {/* <section className="answer-undefined"></section> */}
//           </div>
//           <div className="form-children">
//             {values.additionalQuestionList.map((item: { question: string; answer: string }, index: number) => {
//               return (
//                 <div key={item.question} className="form-holder" data-ft="multiLine" data-vdt="string">
//                   <div className="element element-multiLine">
//                     <header>
//                       <h3>
//                         {item.question}
//                         <span className="isRequired">*</span>
//                       </h3>
//                     </header>
//                     <section className={"answer-" + renderType}>
//                       <Field
//                         name={`additionalQuestionList.${index}`}
//                         render={({ field }: any) => {
//                           if (reportStatus !== COMPLETED_STATUS) {
//                             return (
//                               <>
//                                 <textarea
//                                   className="input"
//                                   style={{ minHeight: "5.2rem" }}
//                                   onChange={(e) => handleChange(field.value, index, e.target.value)}
//                                   value={field.value.answer}
//                                 />
//                                 <FieldInlineError error={errors[index]} />
//                               </>
//                             );
//                           }
//                           return <div>{field.value.answer}</div>;
//                         }}
//                       />
//                     </section>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//       {/* <OptionsList options={elements} {...props} /> */}
//       {reportStatus !== COMPLETED_STATUS && (
//         <footer className="ta-right mt24">
//           <Button type="submit" length="longer" isLoading={isSubmitting} disabled={isFetching}>
//             Submit Additional Answers
//           </Button>
//         </footer>
//       )}
//     </form>
//   );
// };

const segregateIndicators = (indicators: ImportedConfigIndicator[]): SegregatedIndicators => {
  return {
    current: indicators.filter((item: ImportedConfigIndicator) => item.valuePeriod === "current" || !item.valuePeriod),
    past: indicators.filter((item: ImportedConfigIndicator) => item.valuePeriod && item.valuePeriod !== "current"),
  };
};

const getIndicatorIdList = (elements: any): SegregatedIndicators => {
  const initValue: SegregatedIndicators = {
    current: [],
    past: [],
  };
  return elements.reduce((final: any, item: any) => {
    let children = initValue;
    if (item.children && item.children.length > 0) {
      children = getIndicatorIdList(item.children);
    }
    let gridLayout = initValue;
    if (item.fieldType === "gridLayout") {
      // TODO: Cleanup this function
      gridLayout = item.rowElements.reduce((finalrow: any, row: any) => {
        const rowIndicator = row.colElements.reduce((finalcell: any, cell: any) => {
          const cellIndicator = getIndicatorIdList(cell.value);
          return {
            current: [...finalcell.current, ...cellIndicator.current],
            past: [...finalcell.past, ...cellIndicator.past],
          };
        }, initValue);
        return {
          current: [...finalrow.current, ...rowIndicator.current],
          past: [...finalrow.past, ...rowIndicator.past],
        };
      }, initValue);
    }
    let indicator = initValue;
    if (item.fieldType === "indicator") {
      indicator = segregateIndicators(item.config.indicators);
    }
    return {
      current: [...final.current, ...indicator.current, ...children.current, ...gridLayout.current],
      past: [...final.past, ...indicator.past, ...children.past, ...gridLayout.past],
    };
  }, initValue);
};

class ReportView extends Component<Props, State> {
  private modalSuccessRef: React.RefObject<HTMLInputElement> | any = React.createRef();
  state: State = {
    organisationId: this.props.match.params.renderType === "new" ? this.props.match.params.id : null,
    reportId: this.props.match.params.renderType !== "new" ? this.props.match.params.id : null,
    reportType: isNaN(this.props.match.params.reportType as unknown as number)
      ? WINTER_REPORT_NAME
      : this.props.match.params.reportType,
    isSavingDraft: false,
    isFetching: true,
    isFetchingIndicators: true,
    hasErrored: null,
    template: {
      id: "idkid",
      name: "idk",
      elements: [],
    },
    form: {},
    indicators: {
      current: {},
      past: {},
    },
    visibility: {},
    reportStatus: null,
    additionalQuestionList: null,
    recentList: [],
    isFormDirty: false,
    reportSettings: {
      showReadableValues: localStorage.getItem("showReadableValues") === "false" ? false : true,
      showReadableValuesDisabled: false,
    },
    isPreviousAnswersEnabled: false,
  };
  GET_ERROR = (name: string) => this.props.t(ERROR_T_STR + name);
  componentDidMount() {
    // (window as any).accessTokenTemporary = "test";
    const { renderType } = this.props.match.params;
    if (renderType === "new") {
      this.getTemplate(this.props.match.params.id, this.state.reportType);
    } else {
      this.getReportData(
        this.props.match.params.id,
        this.state.reportType,
        renderType === "submit-info" ? "view" : renderType
      );
    }
    this.sendPing(true);
    window.addEventListener("beforeunload", this.beforeunload);
  }

  componentWillUnmount(): void {
    window.removeEventListener("beforeunload", this.beforeunload);
  }

  beforeunload = (e: any): void => {
    if (
      this.state.isFormDirty === true &&
      ["submit", "submit-info", "new"].includes(this.props.match.params.renderType) // ? not view
    ) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  sendPing = (isFirstTime = false): void => {
    if (!isFirstTime && this.state.isFormDirty === false) {
      this.setState({ isFormDirty: true });
    }
    if (["submit", "submit-info"].includes(this.props.match.params.renderType)) {
      // console.log("PING PONG");
      ReportService.sendPing(this.props.match.params.id, this.state.reportType, {
        label: "SIMPLE_REGION_REPORT_SEND_PING",
      });
    }
  };

  throttledPing = throttle(this.sendPing, 3 * 60 * 1000);

  getTemplate = (id: string, type: ReportType) => {
    ReportService.getTemplateByOrganisationIdAndType(id, type, {
      label: "SIMPLE_REGION_REPORT_GET_TEMPLATE",
      onSuccess: (data) => {
        if (data.template) {
          const TEMPLATE = data.template;
          const form = generateForm(TEMPLATE.elements);
          // console.log(form);
          this.setState(
            {
              template: TEMPLATE,
              visibility: this.getVisibility(form, TEMPLATE),
              isPreviousAnswersEnabled: data.isPreviousAnswersEnabled,
            },
            () => {
              this.getAllIndicators(getIndicatorIdList(TEMPLATE.elements), TEMPLATE.elements, form, true);
            }
          );
        } else {
          Toast({ title: ERROR_BODY().title, body: this.GET_ERROR("noTemplate") }, "error", {
            autoClose: false,
          });
          this.setState({ hasErrored: this.GET_ERROR("noTemplate"), isFetchingIndicators: false });
        }
      },
      // onFailure: (error) => {
      //   Toast(ERROR_BODY(), "error", {
      //     autoClose: false,
      //   });
      //   this.setState({
      //     hasErrored: typeof error === "string" ? error : this.GET_ERROR("failToLoadTemplate"),
      //     isFetchingIndicators: false,
      //   });
      // },
      onEnd: () => {
        this.setState({ isFetching: false });
      },
    });
  };

  getAllIndicators = (
    indicatorList: SegregatedIndicators,
    elements: any,
    formValues: FormValues,
    shouldGenerateNewForm = false
  ): void => {
    const shouldGetCurrent = indicatorList.current && indicatorList.current.length >= 1;
    const shouldGetPast = indicatorList.past && indicatorList.past.length >= 1;
    if (!shouldGetCurrent && !shouldGetPast) {
      this.setState({ isFetchingIndicators: false, form: formValues });
      return;
    }
    const currentIndicatorIds: number[] = shouldGetCurrent
      ? indicatorList.current.map((indicator: ImportedConfigIndicator) => +indicator.indicatorId)
      : [];
    const currentIndicatorsPromise = shouldGetCurrent
      ? IndicatorService.getAllIndicatorsByIds(currentIndicatorIds)
      : Promise.resolve({ data: { results: [] } });
    const pastIndicatorsPromise = shouldGetPast
      ? IndicatorService.getPastIndicatorValues(indicatorList.past)
      : Promise.resolve({ data: {} });
    const promises = [currentIndicatorsPromise, pastIndicatorsPromise];
    axios
      .all(promises as Promise<any>[])
      .then(
        (responses: any) => {
          const indicators: CurrentAndPastIndicatorStatus = {
            current: {},
            past: {},
          };
          if (shouldGetCurrent) {
            // console.log({ current: responses[0] });

            indicators.current = arrayToObject(responses[0].data.results, "indicatorId");
          }
          if (shouldGetPast) {
            // console.log({ past: responses[1] });
            indicators.past = responses[1].data;
          }
          const showReadableValuesDisabled = isReadableValuesToggleDisabled(responses[0], responses[1]);
          const form = !shouldGenerateNewForm ? formValues : generateForm(elements, indicators);
          this.setState({
            indicators: indicators,
            form: form,
            reportSettings: {
              showReadableValues: showReadableValuesDisabled ? false : this.state.reportSettings.showReadableValues,
              showReadableValuesDisabled,
            },
          });
        },
        (error: AxiosError) => {
          Toast({ title: ERROR_BODY().title, body: this.GET_ERROR("failToLoadIndicators") }, "error", {
            autoClose: false,
          });
          this.setState({
            hasErrored:
              (error.response && error.response.data && error.response.data.msg) ||
              this.GET_ERROR("failToLoadIndicators"),
          });
        }
      )
      .then(() => {
        this.setState({ isFetchingIndicators: false });
      });
  };

  getReportData = (reportId: string, type: ReportType, renderType: RenderType): void => {
    ReportService.getReportDataAndTemplate(reportId, type, {
      label: "SIMPLE_REGION_REPORT_GET_REPORT_DATA",
      onSuccess: (data) => {
        if (data.template) {
          const TEMPLATE = data.template;
          // console.log(response.data);
          const shouldDisplayFormStraightAway = renderType === "view" || !!data.data;
          // console.log(shouldDisplayFormStraightAway);
          const form = shouldDisplayFormStraightAway ? data.data : generateForm(TEMPLATE.elements);
          // console.log(form);
          this.setState(
            {
              template: TEMPLATE,
              organisationId: data.areaId || data.organisation?.id || null,
              form: renderType === "view" ? form : undefined,
              isFetchingIndicators: renderType === "view" ? false : true,
              reportStatus: data.status,
              additionalQuestionList: (data.additionalQuestionList || []).map(
                (item: { question: string; answer: null | string }) => {
                  return {
                    ...item,
                    answer: item.answer === null ? "" : item.answer,
                  };
                }
              ),
              visibility: this.getVisibility(form, TEMPLATE),
              isPreviousAnswersEnabled: data.isPreviousAnswersEnabled,
            },
            () => {
              if (renderType === "submit") {
                this.getAllIndicators(
                  getIndicatorIdList(TEMPLATE.elements),
                  TEMPLATE.elements,
                  form,
                  data.status === "Draft" ? false : true
                );
              }
              // DOWNLOAD CHECK:
              const downloadId = decodeParams(this.props.location.search).downloadId;
              if (renderType === "view" && !!downloadId) {
                if (typeof (window as any).parent.onDownloadReport === "function") {
                  setTimeout(() => {
                    (window as any).parent.onDownloadReport(
                      downloadId,
                      this.props.match.params.id,
                      (document.querySelector("#content") || { innerHTML: "Error" }).innerHTML
                    );
                  }, 100);
                }
              }
            }
          );
        } else {
          Toast({ title: ERROR_BODY().title, body: this.GET_ERROR("noTemplate") }, "error", {
            autoClose: false,
          });
          this.setState({ hasErrored: this.GET_ERROR("noTemplate"), isFetchingIndicators: false });
        }
      },
      onFailure: () => {
        Toast({ title: ERROR_BODY().title, body: this.GET_ERROR("failToLoadReportData") }, "error", {
          autoClose: false,
        });
      },
      onEnd: () => {
        this.setState({ isFetching: false });
      },
    });
  };

  saveAsDraft = (body: FormValues): AxiosPromise<any> | any => {
    this.setState({ isSavingDraft: true });
    return ReportService.saveAsDraft(body, this.state.organisationId!, this.state.reportType, this.state.reportId, {
      label: "SIMPLE_REGION_REPORT_SAVE_AS_DRAFT",
      onSuccess: (data) => {
        this.setState({ reportId: data.reportId });
        // Toast(this.props.t("toastSuccessDraft", { returnObjects: true }), "success");
      },
      // onFailure: (error) => {
      //   Toast(
      //     {
      //       title: ERROR_BODY().title,
      //       body: typeof error === "string" ? error : this.GET_ERROR("failToSaveDraft"),
      //     },
      //     "error",
      //     {
      //       autoClose: 15000,
      //     }
      //   );
      // },
      onEnd: () => {
        this.setState({ isSavingDraft: false });
      },
    });
  };

  getPrefill = (organisationId: string, reportType: ReportType): void => {
    ReportService.getRecentDataSuggestionList(organisationId, reportType, {
      label: "SIMPLE_REGION_REPORT_GET_PREFILL",
      onSuccess: (data) => {
        this.setState({
          recentList: data.results.sort((a: RecentReport, b: RecentReport) => {
            return a.submittedDatetime < b.submittedDatetime ? (a.submittedDatetime > b.submittedDatetime ? 1 : 0) : -1;
          }),
        });
      },
      onFailure: () => {
        Toast({ title: ERROR_BODY().title, body: this.GET_ERROR("failToLoadRecent") }, "error", {
          autoClose: 10000,
        });
      },
    });
  };

  getVisibility = (values: FormValues, template: TemplateDto): FormStateList => {
    let visibility = {};
    try {
      visibility = getElementVisibility(values, template);
    } catch (error: any) {
      this.setState({ hasErrored: error });
      // console.log({ error });
    }
    return visibility;
  };

  formOnChange = (
    values: FormValues,
    template: TemplateDto,
    indicators: CurrentAndPastIndicatorStatus
  ): FormStateList => {
    // console.log("WHAT");
    // ? sidelogic temp
    const visibility = this.getVisibility(values, template);

    // console.log("%cTEST", "background:red;");
    // console.log("----------___different", visibility);
    if (!isEqual(visibility, this.state.visibility)) {
      console.error("different", visibility);
      this.setState({ visibility: visibility });
    }
    this.throttledPing();
    // ? VALIDATION:
    return { ...validateIndicatorsByThresholds(values, template, indicators), ...validate(values, template) };
  };

  onFill = (reportId: string): void => {
    this.setState({ isFetching: true });
    ReportService.getReportDataAndTemplate(reportId, this.state.reportType, {
      label: "SIMPLE_REGION_REPORT_ON_FILL",
      onSuccess: (data) => {
        if (data.data) {
          // const form = response.data.data;
          const form = generateForm(this.state.template.elements, this.state.indicators, data.data);
          this.setState({
            form: form,
            // isFetchingIndicators: renderType === "view" ? false : true,
            // reportStatus: response.data.status,
            visibility: this.getVisibility(data.data, this.state.template),
          });
        } else {
          Toast({ title: ERROR_BODY().title, body: this.GET_ERROR("noReportData") }, "error", {
            autoClose: false,
          });
          this.setState({ hasErrored: null, isFetchingIndicators: false });
        }
      },
      onFailure: () => {
        Toast({ title: ERROR_BODY().title, body: this.GET_ERROR("failToLoadReportData") }, "error", {
          autoClose: false,
        });
      },
      onEnd: () => {
        this.setState({ isFetching: false });
      },
    });
  };

  toggleReadableValues(_event?: any) {
    if (this.state.reportSettings.showReadableValuesDisabled) {
      return;
    }
    this.setState((prevState) => {
      const prevValue = prevState.reportSettings.showReadableValues;
      localStorage.setItem("showReadableValues", !prevValue as unknown as string);
      return {
        reportSettings: {
          ...prevState.reportSettings,
          showReadableValues: !prevValue,
        },
      };
    });
  }

  // UNSAFE_componentWillMount() {}

  renderAdditionalQuestions() {
    const { reportStatus, additionalQuestionList } = this.state;
    const { renderType } = this.props.match.params;
    return (
      <Formik
        initialValues={{ additionalQuestionList: additionalQuestionList }}
        onSubmit={(values, { setSubmitting }) => {
          ReportService.saveAdditional(
            values.additionalQuestionList,
            this.props.match.params.id,
            this.state.reportType,
            {
              label: "SIMPLE_REGION_REPORT_SUBMIT_ADDITIONAL_QUESTIONS",
              onSuccess: () => {
                this.modalSuccessRef.show();
              },
              onFailure: (error) => {
                // eslint-disable-next-line
                console.log({ error }); //error saving
              },
              onEnd: () => {
                setTimeout(() => {
                  setSubmitting(false);
                }, 600);
              },
            }
          );
        }}
        validate={validateAdditional}>
        {({ ...props }) => {
          return (
            <AdditionalQuestionListForm
              // receivedErrors={this.state.errors}
              // onKeyDown={this.onKeyDown}
              reportStatus={reportStatus}
              renderType={reportStatus === COMPLETED_STATUS ? "view" : renderType}
              isFetching={this.state.isFetching}
              {...props}
            />
          );
        }}
      </Formik>
    );
  }

  modalSuccessStateHandler = (state: string): void => {
    if (state === "CLOSE" && typeof this.props.onSuccessSubmit !== "undefined") {
      this.props.onSuccessSubmit();
    }
  };

  render() {
    const { template, form, reportStatus, additionalQuestionList, visibility, reportType, isPreviousAnswersEnabled } =
      this.state;
    const { renderType } = this.props.match.params;
    // return <Wheel data={wheelData} />;
    if (this.state.isFetching || this.state.isFetchingIndicators) {
      return <ReportEmpty />;
    }
    if (this.state.hasErrored) {
      return (
        <main>
          <InlineError
            icon="error_outline"
            className="middle"
            style={{ height: "30vh" }}
            title={ERROR_BODY().title}
            description={this.state.hasErrored}
          />
        </main>
      );
    }

    if (renderType === "submit" && reportStatus === COMPLETED_STATUS) {
      return (
        <main>
          <InlineError
            icon="error_outline"
            className="middle"
            style={{ height: "30vh" }}
            title={ERROR_BODY().title}
            description={this.GET_ERROR("reportSubmittedBefore")}
          />
        </main>
      );
    }

    return (
      <main>
        <Meta
          title={decodeParams(this.props.location.search).organisation || this.props.t("Report")}
          breadcrumbs={this.props.t("breadcrumbsSingle", {
            returnObjects: true,
            renderType: this.props.t(`renderTypesReadable.${renderType}`),
          })}
        />
        <header className="content-header">
          <div className="ta-right" style={{ width: "100%" }}>
            {!["view", "submit-info"].includes(renderType) && isPreviousAnswersEnabled && (
              <Modal
                trigger={
                  <Button
                    colour="blue-outline"
                    onClick={() => this.getPrefill(this.state.organisationId as string, this.state.reportType)}>
                    {this.props.t("Apply previous answers")}
                  </Button>
                }
                className="dialog">
                <ModalPrefill recentList={this.state.recentList} onFill={this.onFill} />
              </Modal>
            )}
            <Dropdown
              closeDropdownOnClick={false}
              style={{ marginLeft: "0.4rem" }}
              position="right"
              trigger={
                <Button disabled={false} colour="lightgray-outline" length="short">
                  <Icon>settings</Icon>
                </Button>
              }>
              <Dropdown.Item
                style={{ pointerEvents: this.state.reportSettings.showReadableValuesDisabled ? "none" : undefined }}
                onItemClick={() => this.toggleReadableValues()}>
                <Checkbox
                  disabled={this.state.reportSettings.showReadableValuesDisabled}
                  checked={this.state.reportSettings.showReadableValues}
                  isToggle></Checkbox>
                <span style={{ marginLeft: "1rem" }}>{this.props.t("reportSettings.showReadableValues")}</span>
              </Dropdown.Item>
            </Dropdown>
          </div>
        </header>
        <Modal
          ref={(n: any) => (this.modalSuccessRef = n)}
          // modalState={this.modalSuccessStateHandler} // ! TODO
          {...modalSaveSuccessSettings}>
          <ModalSaveSuccess redirectURL={decodeParams(this.props.location.search).source} />
        </Modal>
        <div className={classNames("forms-view", renderType)}>
          {/* <div className="box floating-wheel" style={{ width: WHEEL_SIZE + "px" }}>
            <div className="arrowAnim">
              {[0, 1, 2, 3].map((item: number) => {
                return (
                  <div key={item} className={"delay" + item}>
                    <div />
                  </div>
                );
              })}
            </div>
            <Wheel data={wheelData} />
          </div> */}
          {reportStatus !== COMPLETED_STATUS &&
            renderType === "submit-info" &&
            (additionalQuestionList || []).length >= 1 &&
            this.renderAdditionalQuestions()}
          <Formik
            initialValues={form}
            enableReinitialize={renderType === "submit" ? true : false}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              const body = postProcessAllIndicators(this.state.template.elements, this.state.indicators, values);
              // TODO: Conditional validation check here?
              ReportService.saveReport(
                body,
                this.state.organisationId || -1,
                this.state.reportType,
                this.state.reportId,
                {
                  label: "SIMPLE_REGION_REPORT_SAVE_REPORT",
                  onSuccess: () => {
                    this.modalSuccessRef.show();
                    this.setState({ isFormDirty: false });
                  },
                  onFailure: (error) => {
                    if (error && Array.isArray(error)) {
                      setErrors(error as any);
                    }
                    //  else {
                    //   Toast(
                    //     {
                    //       title: ERROR_BODY().title,
                    //       body: typeof error === "string" ? error : this.GET_ERROR("failToSubmitData"),
                    //     },
                    //     "error",
                    //     {
                    //       autoClose: 15000,
                    //     }
                    //   );
                    // }
                  },
                  onEnd: () => {
                    setTimeout(() => {
                      setSubmitting(false);
                    }, 600);
                  },
                }
              );
            }}
            validate={(values) => this.formOnChange(values, template, this.state.indicators)}>
            {({ errors, ...props }) => {
              return (
                <MainForm
                  // receivedErrors={this.state.errors}
                  // onKeyDown={this.onKeyDown}
                  type={reportType}
                  id={this.props.match.params.id}
                  isSavingDraft={this.state.isSavingDraft}
                  onSaveAsDraft={this.saveAsDraft}
                  reportStatus={reportStatus}
                  renderType={renderType === "submit-info" ? "view" : renderType}
                  isFetching={this.state.isFetching}
                  indicators={this.state.indicators}
                  isFetchingIndicators={this.state.isFetchingIndicators}
                  visibility={visibility}
                  showReadableValues={this.state.reportSettings.showReadableValues}
                  elements={template.elements}
                  errors={errors as unknown as ErrorPlot}
                  {...props}
                />
              );
            }}
          </Formik>
          {reportStatus === COMPLETED_STATUS &&
            (additionalQuestionList || []).length >= 1 &&
            this.renderAdditionalQuestions()}
        </div>
      </main>
    );
  }
}

export default withTranslation("regionReports")(ReportView);
