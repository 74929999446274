import history from "../../history";
import { Toast } from "@tscore/react-components";
import { API_ERROR } from "../actions/api.names";
import { MODULE_URLS } from "../../globals/internal-urls";
import { getErrorToastBody } from "../../lang/error-toasts";
import i18n from "i18next";
// ? TYPES
import { ApplicationState } from "../reducers";

const ERROR_TOAST_AUTO_CLOSE_MS = 9000;

const CODES_WITH_NO_REDIRECT = [403, 404, 401];
// hides the warning token toast
const IGNORE_ERROR_401: string[] = [
  "LOG_IN",
  "PRE_LOGIN",
  "VERIFY_REGISTER_MFA",
  "REGISTER_MFA",
  "LOG_OUT",
  "ACCOUNT_ACTIVATE",
  "FORGOT_SET_PASSWORD",
  "FORGOT_PASSWORD_MAIL",
  // "REFRESH_TOKEN",
];
const IGNORE_ERROR_LABELS: string[] = [
  // "REFRESH_TOKEN",
  "AUTHORIZE",
  "LOG_OUT",
  "PRE_LOGIN",
  "REGISTER_MFA",
  "VERIFY_REGISTER_MFA",
  "LOG_IN",
  "GET_MENU",
  "FORGOT_PASSWORD_MAIL",
  "FORGOT_SET_PASSWORD",
  "ACCOUNT_ACTIVATE",
  "GET_DIAL_INFO",
  "GET_PERSONALISED_INDICATORS",
  "GET_LIVE_SYSTEM_ACTIONS",
  "GET_MY_LIVE_ACTIONS",
  "GET_ESCALATION_PLANS",
  "GET_ESCALATION_HISTORY",
  "GET_GRAPH_CONFIG",
  "UPDATE_PASSWORD",
  "UPDATE_PROFILE",
  "SIMPLE_REGION_REPORT_SEND_PING",
  "SIMPLE_REGION_REPORT_GET_PREFILL",
  "GET_TABLEAU_DASHBOARD_LIST", // only 403 really
  "GET_PTL_DASHBOARD_INDICATOR_LIST", // only 403 really
  "GET_PTL_DASHBOARD_LIST", // only 403 really
];

const TOAST_ERROR_INSTEAD_OF_FULL: string[] = [
  "DOWNLOAD_DOCUMENT",
  "NHSR_DOWNLOAD_DOCUMENT",
  "GET_RESILIENCE_BOUNDARY",
  "GET_RESILIENCE_HOSPITALS",
  "GET_NHSR_PARENT_BOUNDARY",
  "GET_NHSR_CHILD_BOUNDARY",
  // "GET_NHSR_HOSPITALS",
  "REQUEST_REPORT",
  "DOWNLOAD_INDICATOR_OVERVIEW_REPORT",
];

// 401 login again

const errorMiddleware =
  ({ getState }: { getState: () => ApplicationState }) =>
  (next: any) =>
  (action: { type: string; [key: string]: any } = { type: "" }): any => {
    if (action.type === API_ERROR) {
      const code = action.payload.error.request?.status || -1;
      if (
        code === 401 &&
        !IGNORE_ERROR_401.includes(action.payload.label) &&
        ["invalid_token", "unauthorized request", "unauthorized"].includes(action.payload.errorMessage)
      ) {
        Toast(i18n.t("toastsMisc:token-expired", { returnObjects: true }), "warning", {
          autoClose: ERROR_TOAST_AUTO_CLOSE_MS,
          toastId: "token-expired-id",
        });
        return next({ type: "LOG_OUT" });
      }
      const method = action.payload.error.config?.method || "other";
      if (!IGNORE_ERROR_LABELS.includes(action.payload.label)) {
        if (method === "get" && !TOAST_ERROR_INSTEAD_OF_FULL.includes(action.payload.label)) {
          const redirectUrl = history.location.pathname;
          let redirectType = "replace";
          const currentModule = getState().menuReducer.meta.module;
          const modulePrefix = MODULE_URLS[currentModule];
          // const isNHSR = action.payload.label.startsWith("NHSR_") ? "/nhsr" : "";
          let url = `${modulePrefix}error/${code}`;
          if (!CODES_WITH_NO_REDIRECT.includes(code)) {
            url = url + `?redirectURL=${redirectUrl}`;
            redirectType = "push";
          }
          redirectType === "push" ? history.push(url) : history.replace(url);
        } else {
          Toast(getErrorToastBody(action), "error", {
            autoClose: ERROR_TOAST_AUTO_CLOSE_MS,
          });
        }
      }
    }
    return next(action);
  };

export default errorMiddleware;
