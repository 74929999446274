import { FOLDER_ID_DELIMINATOR } from "../../../globals/tableau-settings";
import { IntegrationPlot } from "../../reducers/integrations/consts";
// ? TYPES:
import { IntegrationListItem } from "../../types/integrations-state";

interface IndicatorIntegrationLink {
  indicatorId: number;
  link: string;
}

type InitialIntegrationListItem = IntegrationListItem & { indicatorIntegrationLinkList: IndicatorIntegrationLink[] };

type AppendedKeys = "dynamicId" | "dynamicIdKey" | "linkOrigin" | "linkId";

type OmittedIntegrationListItem = Omit<IntegrationListItem, AppendedKeys>;

function getDynamicId(item: OmittedIntegrationListItem) {
  const dynamicIdChecks: { key: keyof OmittedIntegrationListItem; condition: () => boolean }[] = [
    { key: "contractId", condition: () => item.contractId > 0 },
    { key: "dashboardId", condition: () => item.dashboardId > 0 },
  ];
  const dynamicIdKey = dynamicIdChecks.find(({ condition }) => condition())?.key || "integrationLinkId";
  return {
    dynamicIdKey,
    dynamicId: item[dynamicIdKey] as number,
  };
}

function getIntegrationLinkDetails(item: Pick<IntegrationListItem, "integrationId" | "link">): {
  linkOrigin: string;
  linkId: string;
  link?: string;
} {
  const isValidUrl = item.link.startsWith("https://");
  if (!isValidUrl) {
    return { linkOrigin: "", linkId: "" };
  }
  const url = new URL(item.link);
  const originSeparator = (url.searchParams as any).size > 0 ? "&" : "?";
  const link = `${item.link}${originSeparator}origin=shrewd`;
  if (item.integrationId === IntegrationPlot.TABLEAU) {
    const [tFolder, tId] = url.pathname.split("/").slice(-2);
    const linkId = tFolder && tId ? tFolder + FOLDER_ID_DELIMINATOR + tId : "";
    return {
      linkOrigin: url.origin,
      linkId,
      link,
    };
  }
  return { linkOrigin: "", linkId: "", link };
}

function updateIntegrationListItem({
  indicatorIntegrationLinkList,
  ...item
}: Omit<InitialIntegrationListItem, AppendedKeys>): IntegrationListItem[] {
  const base = {
    ...item,
    link: "",
    description: item.description || "",
    ...getDynamicId(item),
  };
  if (indicatorIntegrationLinkList && indicatorIntegrationLinkList.length > 0) {
    return indicatorIntegrationLinkList.map((link) => {
      const item = {
        ...base,
        ...link,
      };
      return {
        ...item,
        ...getIntegrationLinkDetails(item),
      };
    });
  }
  return [
    {
      ...base,
      ...getIntegrationLinkDetails(item),
    },
  ];
}

export const transformGetIntegrations = (response: {
  results: Omit<InitialIntegrationListItem, AppendedKeys>[];
}): { results: IntegrationListItem[] } => {
  return {
    ...response,
    results: response.results.map(updateIntegrationListItem).flat(1),
  };
};
