import React from "react";
import { Select, selectComponents as components, TSelectProps } from "@tscore/react-components";
import classNames from "classnames";
import { useTranslation, Trans } from "react-i18next";
import { TIndicatorGroupControlType } from "../../globals";
// import cloneDeep from "lodash/cloneDeep";

// import { Props } from "react-select";

// import { FilterIndicator } from "../types";

interface FilterIndicator {
  name: string;
  selected: boolean;
  [key: string]: any;
}

// export interface SelectProps extends TSelectProps<FilterIndicator> {
//   scale?: string;
//   name?: string;
// // defaultValue: any;
// }

interface SelectProps<T = FilterIndicator> extends TSelectProps<T> {
  selectType?: TIndicatorGroupControlType;
  maxOptionsCollapsed?: number;
  optionValueName: string;
  optionLabelName?: string;
  placeholderKey?: string;
  placeholderKeyOne?: string;
}

type Status = "ALL" | "NONE" | "SOME";

// const defaultClass = "select";
const WIDTH_MULTIPLIER = 6.2;

const ValueContainer = ({ children, ...props }: any) => {
  const { t } = useTranslation();
  const { getValue, options, hasValue } = props;
  const values = getValue();
  let length = values.length;
  const nameList: string | false =
    props.selectProps.maxOptionsCollapsed >= length
      ? values.map((v: any) => props.selectProps.getOptionLabel(v)).join(", ")
      : false;
  const allLength = options.length;
  if (length === allLength && length !== 0) {
    length = "All";
  }
  // const isS = length === 1 || props.selectProps.placeholderKey === "" ? "" : "s";
  // const newChildren = cloneDeep(children);
  const isOpen = props.selectProps.menuIsOpen;
  if (isOpen) {
    const openChildren = [...children];
    const placeholderText = props.selectProps.inputValue.length === 0 ? t("generic:Search") + "..." : "";
    openChildren[0] = (
      <div key="placeholderNew" className="select__placeholder">
        {placeholderText}
      </div>
    );
    return <components.ValueContainer {...props}>{openChildren}</components.ValueContainer>;
  }
  const newChildren = [...children];
  newChildren[0] = (
    <div key="value-container" style={{ width: nameList ? "95%" : undefined }}>
      {nameList ? (
        <span
          style={{
            whiteSpace: "nowrap",
            // textOverflow: "ellipsis",
            // overflow: "hidden",
            width: "100%",
            display: "inline-block",
          }}>
          {nameList}
        </span>
      ) : (
        <span>
          <Trans
            i18nKey="map:numberSelected"
            values={{
              key:
                length === 1 && props.selectProps.placeholderKeyOne
                  ? props.selectProps.placeholderKeyOne
                  : props.selectProps.placeholderKey,
              count: length,
            }}
            // count={length}
            components={[
              <span key={0} className="selectedIndicatorNumber">
                n
              </span>,
            ]}></Trans>
          {/* <span className="selectedIndicatorNumber">{length}</span>{" "}
          {length === 1 && props.selectProps.placeholderKeyOne
            ? props.selectProps.placeholderKeyOne
            : props.selectProps.placeholderKey}
          {isS} selected */}
        </span>
      )}
    </div>
  );
  if (!hasValue) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }
  return <components.ValueContainer {...props}>{newChildren}</components.ValueContainer>;
};

const selectedNumber = (hasValue: boolean, options: any[], selected: any[]): Status => {
  if (!hasValue) {
    return "NONE";
  }
  if (selected.length >= options.length) {
    return "ALL";
  }
  return "SOME";
};

// ? https://material-ui.com/components/tables/#sorting-amp-selecting reference
const MenuList = ({ hasValue, options, getValue, clearValue, setValue, isMulti, ...props }: any) => {
  const { t } = useTranslation();
  const showSelectAll =
    props.selectProps.inputValue.length === 0 && props.selectProps.options.length > 0 ? true : false;
  const o = [...options!];
  const longest: number = o.reduce((r: number, s: any) => (r > s.name.length ? r : s.name.length), 0);
  const minWidth = WIDTH_MULTIPLIER * longest + "px";
  if (!showSelectAll || !isMulti) {
    return (
      <components.MenuList {...props}>
        <div className="select__option__fixer" style={{ minWidth }} />
        {props.children}
      </components.MenuList>
    );
  }
  const values = getValue();
  const status = selectedNumber(hasValue, options, values);
  const classes = classNames("select__option", "select__option__fixer", `select__status--${status}`, {
    "select__option--is-selected": status === "ALL",
  });
  const onClick = (status: Status): void => {
    if (status === "ALL") {
      clearValue();
    } else {
      setValue(
        options.map((option: FilterIndicator) => ({ ...option, selected: true })),
        "set-value"
      );
    }
  };
  return (
    <components.MenuList {...props}>
      <div className={classes} tabIndex={-1} style={{ minWidth }} onClick={() => onClick(status)}>
        <div>
          <label className="checkbox small" onClick={(e: any) => e.preventDefault()}>
            <input type="checkbox" readOnly checked={status !== "NONE"} />
            <span></span>
          </label>
          <span>{status === "ALL" ? t("generic:Unselect All") : t("generic:Select All")}</span>
        </div>
      </div>
      <hr style={{ margin: "0.8rem 0" }} />
      {props.children}
    </components.MenuList>
  );
};

// const Placeholder = (props: any) => {
//   const isOpen = props.selectProps.menuIsOpen;
//   console.log(props);
//   if (isOpen) {
//     return <components.Placeholder {...props}>Search...</components.Placeholder>;
//   }
//   return <components.Placeholder {...props} />;
// };

const formatOptionLabel = (
  item: FilterIndicator,
  { context, selectValue }: { context: "menu" | "value"; selectValue: any[] },
  optionValueName: string,
  filterSelectType: TIndicatorGroupControlType
): React.ReactNode => {
  const { name } = item;
  // console.log(labelMeta);
  // console.log(selected);
  const isSelected = selectValue.find((sv) => sv[optionValueName] === item[optionValueName]) ? true : false;
  const inputType = filterSelectType === "MULTI_SELECT_DROPDOWN" ? "checkbox" : "radio";
  if (context === "menu") {
    return (
      <div title={name} data-value={item[optionValueName]}>
        <label className={classNames(inputType, "small")} onClick={(e: any) => e.preventDefault()}>
          <input type={inputType} checked={isSelected} readOnly />
          <span></span>
        </label>
        <span>{name}</span>
        {/* <span>{name}</span> */}
        {/* <div style={{ marginLeft: "10px", color: "#ccc" }}>{indicatorGroupId}</div> */}
      </div>
    );
  }
  // const length = selectValue ? selectValue.length : 0;
  return <div>y</div>;
};

export const FilterSelect: React.FC<SelectProps> = ({
  className,
  name,
  defaultClass,
  scale,
  maxOptionsCollapsed = 0,
  optionValueName,
  optionLabelName = "name",
  placeholder = "0 selected",
  placeholderKey = "item",
  placeholderKeyOne,
  maxMenuHeight = 500,
  selectType = "MULTI_SELECT_DROPDOWN",
  ...rest
}) => {
  const classes = classNames("indicator-select", defaultClass, className, scale);
  return (
    <Select
      formatOptionLabel={(option: any, action: any) => formatOptionLabel(option, action, optionValueName, selectType)}
      className={classes}
      isMulti={selectType === "MULTI_SELECT_DROPDOWN"}
      hideSelectedOptions={false}
      maxMenuHeight={maxMenuHeight}
      closeMenuOnSelect={false}
      controlShouldRenderValue={false}
      isClearable={false}
      isSearchable
      placeholderKey={placeholderKey}
      placeholderKeyOne={placeholderKeyOne}
      maxOptionsCollapsed={maxOptionsCollapsed}
      name={name}
      components={{ ValueContainer, MenuList }}
      getOptionValue={(option: any) => option[optionValueName]}
      getOptionLabel={(option: any) => option[optionLabelName]}
      blurInputOnSelect={selectType === "SINGLE_SELECT_DROPDOWN"}
      backspaceRemovesValue={false}
      // openMenuOnFocus
      placeholder={placeholder}
      {...rest}
    />
  );
};

// export { ValueType };
